<template>
    <div>
      <v-data-table :headers="headers" :items="tags" item-value="tag_name" class="elevation-1" 
        :items-per-page-options="[5, 10, 15, 20]">
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>TagSettings</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <!-- <v-checkbox v-model="can_manual_tagging" label="Enable Manual Tagging" style="margin-top: 2%;" color="success" @click="toggleSwitch" ></v-checkbox>
            <v-spacer></v-spacer> -->
            <v-btn color="green" @click="openAddTagDialog" :disabled="!can_manual_tagging">Create New Tag</v-btn>
            <v-spacer></v-spacer>
            <v-icon color="red" @click="closeTagSettings">mdi-close</v-icon>
          </v-toolbar>
        </template>
  
  
        <template v-slot:item.tag_name="{ item }">
          <div @click.prevent="editOrDeleteTag(item)"
            style="margin-left: 8px; text-decoration: none; color: black; cursor: pointer;">
            {{ item.tag_name }}
          </div>
        </template>
  
  
        <template v-slot:item.tag_icon="{ item }">
          <div @click.prevent="editOrDeleteTag(item)"
            style="margin-left: 8px; text-decoration: none; color: black;cursor: pointer;">
            <v-icon :color="item.tag_color">mdi-{{ item.tag_icon }}</v-icon>
          </div>
        </template>
  
        <template v-slot:item.enable_auto="{ item }">
          <span @click.prevent="editOrDeleteTag(item)" :style="{ cursor: 'pointer', color: item.enable_auto ? '#0BDA51' : '#FF0000' }">{{ item.enable_auto ? 'True' : 'False'
            }}</span>
        </template>
  
  
        <template v-slot:item.unlist="{ item }">
          <span @click.prevent="editOrDeleteTag(item)" :style="{ cursor: 'pointer', color: item.unlist ? '#0BDA51' : '#FF0000' }" >{{ item.unlist ? 'True' : 'False'
            }}</span>
        </template>
      </v-data-table>
  
      <!-- Tag create,edit and delete form -->
      <TypeTagForm v-if="dialog" v-model="dialog" @close="closeDialog" :isEdit="isTagEdit" :incoming_Tag="localTag"
        :contactType="null" />
  
    </div>
  </template>
  
  <script>
  import TypeTagForm from '../AdminSettings/TypeTagForm.vue';
  import { mapGetters, mapActions, mapMutations } from "vuex";
  export default {
    name: "TagSettings",
    data: () => ({
      headers: [
        { title: 'Tag Name', value: 'tag_name',align: 'center'},
        { title: 'Tag Icon', value: 'tag_icon',align: 'center'},
        { title: 'Enable Auto', value: 'enable_auto', align: 'center' },
        { title: 'Block Further Usage', value: 'unlist', align: 'center' },
      ],
      dialog: false,
      isTagEdit: false,
      localTag: {
        id: null,
        name: '',
        icon: '',
        color: '#FF2929',
        enable_auto: false,
        unlist: false
      },
    }),
    components: { TypeTagForm },
    computed: {
      ...mapGetters("tags", ["tags","can_manual_tagging"]),
      ...mapActions("tags", ["getTags"]),
      
    },
    methods: {
      ...mapMutations("tags",["manual_tagging"]),
      // Enable And Disable Manual Tagging
      toggleSwitch(){
        this.manual_tagging(!this.can_manual_tagging);
      },
      openAddTagDialog() {
        this.dialog = true;
        this.resetNewTag(); // Reset fields for a new entry
        this.isTagEdit = false;
      },
  
      editOrDeleteTag(item) {
        if(!this.can_manual_tagging){
            return;
        }
        this.isTagEdit = true;
        this.localTag = {
          id: item.tag_id,
          name: item.tag_name,
          icon: item.tag_icon,
          color: item.tag_color,
          enable_auto: item.enable_auto,
          unlist: item.unlist
        };
  
        this.dialog = true;
      },
  
      resetNewTag() {
        this.localTag = {
          id: null,
          name: '',
          icon: '',
          color: '#FF2929',
          enable_auto: false,
          unlist: false
        };
      },
    //   Tag add, edit dialog 
      closeDialog() {
        this.dialog = false;
      },
      closeTagSettings(){
        this.$emit('closeTagSettings')
      }
    },
    async created(){
      if(this.tags){
        await this.getTags;
      }
    }
  };
  </script>
<style>

.v-data-table thead th {
  font-weight: bold !important; /* Make header text bold */
}
</style>