import http from "../http-common";

class MiscService {
  getContacts = () => http.get("/contacts");

  getUser = (user_id) => http.get(`/users/${user_id}`);

  getUsers = () => http.get("/users");

  getProcess = ()=> http.get("/processtypes")

  addProcess = (type_name)=> http.post("/addprocess",{type_name})

  getOutstandingClaims = () => http.get("/outstanding-claims");

  getRecalls = () => http.get("/recalls");

  getAppointments = (contact_id) => http.get(`/contacts/${contact_id}/appointments`);

  getCalls = () => http.get("/calls");

  getCallRecordingURL = (call_uuid) => http.defaults.baseURL + `/calls/${call_uuid}/recording`;

  // getTexts = () => http.get("/texts");

  // getEmails = () => http.get("/emails");

  updateActiveStatus = (user_id) => http.put(`/admin/update-active-status/${user_id}`);

  updatePassword = (data) => http.put("/settings/password", data);

  sendAccountActivationEmail = (email, data) => http.post(`activate-account/${email}`, data);

  updateDarkMode = (dark_mode) => http.post("/user/dark_mode", { dark_mode });

  setContactType =(contact_id,type_id) => http.put(`/contact/${contact_id}/contact_type/${type_id}`);

  getAllCalls = (contact_id) => http.get(`/allcalls/${contact_id}`);
  

  getAllCallsForPhone = (contact_id) => http.get(`/allcalls/phone/${contact_id}`);
  getAllCallsForContact = (contact_id) => http.get(`/allcalls/contact/${contact_id}`);

  // getAllUserCalls = (user_id) => http.get(`/allcalls/users/${user_id}`);
  getAllUserCalls = (user_id, timestamp) => http.get(`/allcalls/users/${user_id}?timestamp=${timestamp}`);


  getMultimediaUrl = (textMsgId, mediaId) => http.defaults.baseURL + `/messages/text_message/${textMsgId}/media/${mediaId}`;

  getMessageMediaForContact = (contact_id) => http.get(`/messages/message_media/contact/${contact_id}`);

  getContactTypes = () => http.get(`/contact_types`);
}

export default new MiscService();
