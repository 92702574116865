<template>
    <v-card 
        variant="plain"
        style="max-width: max-content; min-height: max-content; padding: auto;" 
        @mouseenter="stopCloseTimer" 
        @mouseleave="startCloseTimer"
        elevated="3"
    >
    <v-chip variant="outlined" color="success" style="padding: auto;">
        <div v-if="checkAdminRole && !isMassTag" style="margin-right: 6%; margin-left: -5%; opacity: 0.5;" @click="openTagSettings">
            <v-icon size="18" color="secondary">
                mdi-plus-circle</v-icon>
            <v-tooltip activator="parent" location="bottom">
               Add / Edit Tag</v-tooltip>
        </div>
        <div v-for="tag in tags" :key="tag.tag_id" style="display: inline;">
            <v-tooltip :location="'bottom'" :text="tag.tag_name">
                <template v-slot:activator="{ props }">
                    <div v-bind="props" style="display: inline; padding: 0.2rem;">
                        <!-- <v-chip variant="elevated"  style="padding: auto;"> -->
                        <v-icon size="20" :color="tag.tag_color" @click="selectTag(tag.tag_id)" elevation="5">
                            mdi-{{ tag.tag_icon }}
                        </v-icon>
                    <!-- </v-chip> -->
                    </div>
                </template>
            </v-tooltip>
        </div>
    </v-chip>  
    </v-card>
</template>

<script>
import { mapGetters} from "vuex";
import EventBus from "@/eventBus";
export default {
    name: 'TagMenu',
    props: {
        tags: { type: Array, required: true },
        isMassTag : { type: Boolean, default : false}
    },
    data() {
        return {
            closeTimer: null,
        };
    },
    computed:{
        ...mapGetters(['userRole']),
        // for now only admin can edit, delete,create contact type and tag.
        checkAdminRole(){
            return this.userRole.toLowerCase() === 'admin';
        },
    },
    methods: {
        selectTag(tag) {
            this.$emit('applyTag', tag);
        },
        // At Mouse Leave component, will close in 3sec 
        startCloseTimer() {
            if (!this.closeTimer && !this.isMassTag) {
                this.closeTimer = setTimeout(() => {
                    this.$emit('closeMenu');
                }, 2500);
            }
        },
        stopCloseTimer() {
            if (this.closeTimer) {
                clearTimeout(this.closeTimer);
                this.closeTimer = null;
            }
        },
        openTagSettings(){
            EventBus.$emit('open-tag-settings');
        }
        
    },
    mounted() {
        this.startCloseTimer();
    },
    beforeUnmount() {
        this.stopCloseTimer();
    },
};
</script>
