<template>
    <div style="width: 550px">
      <v-card elevation="4" color="white">
          <v-card-title class="d-flex justify-space-between align-center">
    <div class="d-flex align-center">
      <v-icon large left color="primary">mdi-filter</v-icon>
      <span class="text-h6 font-weight-light mr-3">Filter</span>
    </div>
    <v-icon size="20" color="red" class="clickable" @click="closeFilter">mdi-close</v-icon>
  </v-card-title>
        <v-row>
          <v-col>
            <v-container>
              <v-select
                v-model="selectedTypes"
                :items="conTypes"
                item-value="type_name"
                placeholder="Select to filter"
                label="Contact Types"
                @update:model-value="handleSelectTypes"
                multiple
                variant="underlined"
                style="min-width: 120px"
                color="success"
                clearable
                :disabled="selectedODlist!=null"
              >
                <template v-slot:item="{ item, props }">
                  <div
                    v-bind="props"
                    :style="{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 8px',
                      backgroundColor: selectedTypes.includes(item.value)
                        ? '#DDEEFF'
                        : 'transparent',
                    }"
                  >
                   
                    <v-avatar
                     rounded="lg"
                     size="x-small"
                    :color="item.props.title.color">
                    
                      <v-btn icon size="x-small" variant="text" >
                          <v-icon>mdi-account-outline</v-icon>
                        </v-btn>
                      </v-avatar>
                       
                    <span :style="{ textAlign: 'left', flex: 1 , padding: '3px'}">
                      {{item.value}}
                    </span>
                  </div>
                </template>
  
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index === selectedTypes.length - 1"
                    label
                    small
                    closable
                    color="indigo-darken-1"
                    class="rounded-md"
                    @click:close="removeTypeSelectedChip(item.value)"
                  >
                    <span>{{ item.value }}</span>
                    <template v-slot:close>
                      <v-icon color="red" size="15">mdi-close</v-icon>
                    </template>
                  </v-chip>
  
                  <span
                    v-if="index === selectedTypes.length - 2"
                    class="grey--text caption"
                  >
                    <v-chip label size="30" color="indigo-darken-1"
                      >+{{ selectedTypes.length - 1 }}</v-chip
                    >
                  </span>
                </template>
              </v-select>
            </v-container>
          </v-col>
          <!-- We Blocked Tag Filter for V1 -->
          <!-- <v-col>
            <v-container>
              <v-select
                v-model="selectedTags"
                :items="conTags"
                item-value="tag_name"
                placeholder="Select to filter"
                label="Contact Tags"
                @update:model-value="handleSelectTags"
                multiple
                variant="underlined"
                style="min-width: 120px"
                clearable
                color="success"
                :disabled="selectedODlist!=null"
              >
                <template v-slot:item="{ item, index, props }">
                  <div
                    v-bind="props"
                    :style="{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 8px',
                      backgroundColor: selectedTags.includes(item.value)
                        ? '#DDEEFF'
                        : 'transparent',
                    }"
                  >
                    <v-icon class="mr-2" size="15" :color="item.props.title.tag_color">
                      mdi-{{ item.props.title.tag_icon }}
                    </v-icon>
                    <span :style="{ textAlign: 'left', flex: 1 }">
                      {{ item.props.title.tag_name }}
                    </span>
                  </div>
                </template>
  
                <template v-slot:selection="{ item, index }">
                  <v-chip
                    v-if="index === selectedTags.length - 1"
                    label
                    small
                    closable
                    color="indigo-darken-1"
                    class="rounded-md"
                    @click:close="removeTagSelectedChip(item.value)"
                  >
                    <span>{{ item.props.title.tag_name }}</span>
                    <template v-slot:close>
                      <v-icon color="red" size="15">mdi-close</v-icon>
                    </template>
                  </v-chip>
                  <span
                    v-if="index === selectedTags.length - 2"
                    class="grey--text caption"
                  >
                    <v-chip label size="30" color="indigo-darken-1"
                      >+{{ selectedTags.length - 1 }}</v-chip
                    >
                  </span>
                </template>
              </v-select>
            </v-container>
          </v-col> -->
          <v-col>
            <v-container>
              <v-select
                v-model="selectedODlist"
                label="Get from Open Dental"
                :items="odList"
                item-value="name"
                @update:model-value="handleSelectODlist"
                variant="underlined"
                style="min-width: 120px"
                clearable
                color="success"
                :disabled="selectedTypes.length!==0 || selectedTags.length!=0"
              >
              <template v-slot:item="{ item, props }">
                  <div
                    v-bind="props"
                    :style="{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '5px 8px',
                    }"
                  >
                    <v-icon class="mr-2" size="15" :color="item.props.title.color">
                      mdi-{{ item.props.title.icon }}
                    </v-icon>
                    <span :style="{ textAlign: 'left', flex: 1 }">
                      {{ item.value }}
                    </span>
                  </div>
                </template>
                <template v-slot:selection="{ item }">
                  <v-icon  class="mr-2" size="15" :color="item.props.title.color">
                    mdi-{{ item.props.title.icon }}
                  </v-icon>
                  <span>{{ item.value }}</span>
                </template>
              </v-select>
            </v-container>
          </v-col>
        </v-row>
      </v-card>
    </div>
    
  </template>
  
  <script>
  
  export default {
    name: "AllFilter",
    props: {
      conTypes: { type: Array, required: true },
      type_selectedValues: { type: Array, default: () => [] },
      conTags: { type: Array, required: true },
      tag_selectedValues: { type: Array, default: () => [] },
      odList: { type: Array, required: true},
      od_selectedValue: { type: String},
    },
    data() {
      return {
        selectedTypes: this.type_selectedValues,
        selectedTags: this.tag_selectedValues,
        selectedODlist : this.od_selectedValue
      };
    },
    methods: {
      handleSelectTypes(newValues) {
        this.selectedTypes = newValues;
        this.$emit("update:type_selectedValues", this.selectedTypes);
        this.$emit("apply-filter");
      },
      handleSelectTags(newValues) {
        this.selectedTags = newValues;
        this.$emit("update:tag_selectedValues", this.selectedTags);
        this.$emit("apply-filter");
      },
      // for now we select one item at a time, no multiple select
      handleSelectODlist(value){
       
          this.selectedODlist = (value);
          this.$emit("update:od_selectedValue", this.selectedODlist);
          this.$emit("get-odData");
        
      },
      removeTypeSelectedChip(value) {
        this.selectedTypes = this.selectedTypes.filter((v) => v !== value);
        this.$emit("update:type_selectedValues", this.selectedTypes);
        this.$emit("apply-filter");
      },
      removeTagSelectedChip(value) {
        this.selectedTags = this.selectedTags.filter((v) => v !== value);
        this.$emit("update:tag_selectedValues", this.selectedTags);
        this.$emit("apply-filter");
      },
      //Colse The Filter Component
      closeFilter() {
        this.$emit("closeFilter");
      },
     
    },
  };
  </script>
  
  <style scoped>
  
  </style>