<template>
  <div class="price-management">
    <h1>Price Management</h1>
    <p v-if="!(this.userRole=='admin' && this.clientId=='1')" class="access-denied">
      Access denied
    </p>

    <div v-else>
      <table class="plans-table">
        <thead>
          <tr>
            <th>Plan Name</th>
            <th>Renewal Period</th>
            <th>Features</th>
            <th>Current Price</th>
            <th>New Price</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="plan in plans" :key="plan.id">
            <td>{{ plan.name }}</td>
            <td>{{ plan.renewal }}</td>
            <td>{{ plan.features.join(", ") }}</td>
            <td>${{ plan.price }}</td>
            <td>
              <input
                type="number"
                v-model.number="newPrices[plan.id]"
                placeholder="Enter new price"
                class="price-input"
              />
            </td>
            <td>
              <button
                @click="updatePrice(plan)"
                :disabled="!newPrices[plan.id]"
                class="update-button"
              >
                Update Price
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import adminService from "../../services/adminService";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      plans: [],
      newPrices: {},
    };
  },
  computed: {
    ...mapGetters(["clientId", "userRole"]),
  },
  methods: {
    async fetchPlans() {
      const response = await adminService.planDetails();
      this.plans = response.data.plans;
    },
    async updatePrice(plan) {
      const newPrice = this.newPrices[plan.id];
      if (newPrice && newPrice > 0) {
        try{
            const responce = await adminService.changePlanPrice(plan.id,parseInt(newPrice));
        }
        catch(err){
          console.error("error changing plan price",err);
        }
        alert(
          `Price for ${plan.name} (${plan.renewal}) updated to $${newPrice}`
        );
        plan.price = newPrice;
        this.$set(this.newPrices, plan.id, null);
      } else {
        alert("Please enter a valid price.");
      }
    },
  },
  async mounted() {
    await this.fetchPlans();
  },
};
</script>

<style scoped>
.price-management {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.access-denied {
  color: red;
  text-align: center;
  font-weight: bold;
}

.plans-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.plans-table th,
.plans-table td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
}

.plans-table th {
  background-color: #007bff;
  color: white;
  font-weight: bold;
}

.plans-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.price-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.update-button {
  padding: 8px 16px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.update-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
