import http from "../http-common";

class TagService {
// Tags
  //Create new tag
  createNewTag = (data) => http.post('/tag', data);

  //update tag
  updateTag = (tag_id,data)=>http.put(`/tag/${tag_id}`,data);

  //delete tag
  deleteTag = (tag_id)=>http.delete(`/tag/${tag_id}`);

  //get tags
  getTags = () => http.get(`/tag`);

  //tags for contact
  //add tag for contact
  addTagforContact = (contact_id,data) => http.post(`/contact_tags/${contact_id}`,data);

  //remove tag for contact
  removeTagforContact = (contact_id,tag_id) => http.delete(`/contact_tags/${contact_id}/tag_id/${tag_id}`);
  
  isTagEditable = (tag_id) => http.get(`/tag/edit/${tag_id}`);

  applyMassTagforContact = (data) => http.post(`/masstag`,data);

  //Conversation Tag
  addConversationTag = (contact_id, data) => http.post(`/conversation_tag/${contact_id}`,data);

  removeConversationTag = (conversation_id, tag_id) => http.delete(`/conversation_tag/conversation_id/${conversation_id}/tag_id/${tag_id}`);

}
export default new TagService();