<template>
    <div>
      <v-app style="display: flex">
        <v-navigation-drawer permanent expand-on-hover rail app>
          <v-list dense>
            <v-list-item 
            v-for="item in navbarItems" :key="item.tab"  :title="item.title"  @click="showComponent(item.tab)"
             :class="{ highlighted:  true }"
            >
              <template v-slot:prepend>
                <v-icon style="opacity: 1.2;" :color="currentComponent === item.tab ? 'primary' : 'secondary'">
                  {{ item.icon}}
                </v-icon>
              </template>
            </v-list-item>
          </v-list>
        </v-navigation-drawer>
  
        <!-- Main Area to Load Selected Components -->
        <v-main>
          <component :is="currentComponent" />
        </v-main>
      </v-app>
    </div>
  </template>
  
  <script>
  import ClientEndpoints from "./ClientEndpoints.vue";
  import ClientPhonesList from "./ClientPhonesList.vue";
 
  export default {
    name: "Settings",
    data() {
      return {
        currentComponent: 'ClientEndpoints', // temp default component
      };
    },
    components: {
      ClientEndpoints,
      ClientPhonesList
    },
    computed: {
      navbarItems() {
        return this.getItems();
      },
    },
    methods: {
      showComponent(component) {
        this.currentComponent = component;
      },
      getItems() {
        return[
          {
            tab: "ClientPhonesList",
            icon: "mdi-phone-settings",
            title: " Client Phones",
          },
          {
            tab: "ClientEndpoints",
            icon: "mdi-face-agent",
            title: " User Endpoints",
          },
         
        ];
  
      },
    },
  };
  </script>