import http from "../http-common";

class OdService {

    getContact = (contact_id) => http.get(`/crm/contacts/${contact_id}`);

    addContact = (data) => http.post(`/crm/contacts`, data);

    updateContact = (contact_id, data) => http.put(`/crm/contacts/${contact_id}`, data);

    addDocument = (text_message_id, media_id) => http.post(`od/documents/${text_message_id}/${media_id}`);

    getContactAppointments = (contact_id) => http.get(`/od/contacts/${contact_id}/appointments`);

    getFreeSlots = () => http.get('od/appointments/slots');

    addContactAppointment = (contact_id, data) => http.post(`/contacts/${contact_id}/appointments`, data);

    getRecare_List = (offset) => http.get(`/od/recalls/offset/${offset}`);

    getReConsultant_List = () => http.get(`/od/reconsult`);

    getPostOps_List = (offset) => http.get(`/od/postops/offset/${offset}`);

    getUnConfirmedAppointment_List = (offset) => http.get(`/od/unconfirmedappts/offset/${offset}`);

}

export default new OdService();