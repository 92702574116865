<template>
  <v-menu
    :content-class="filteredSearchMasterList.length == 0 ? 'elevation-0' : 'elevation-10'"
    nudge-left="60"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ props }">
      <div
        class="pa-1 pl-3 pr-3"
        style="
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 250px;
        "
      >
        <v-text-field
          v-bind="props"
          type="text"
          autocomplete="off"
          height="7"
          style="flex: 1; height: 45px; overflow-y: none; margin-bottom: 5px; margin-left: 5px; padding-right: 0px;"
          v-model="textfieldInput"
          variant="plain"
          hide-details
          single-line
          density="compact"
          label="Enter a name or number"

        ></v-text-field>

        <v-icon v-if="textfieldInput !== null" class="mr-1" size="20" color="blue" @click="textfieldInput = null">
          mdi-close
        </v-icon>

        <v-btn
          v-if="validNumber"
          size="x-small"
          rounded
          class="mr-1"
          color="green lighten-3"
          @click="$emit('callPressed', textfieldInput)"
        >
          <v-icon size="15" color="green darken 1">mdi-phone</v-icon>
        </v-btn>
      </div>
    </template>
    <v-card v-show="filteredSearchMasterList.length">
      <RecycleScroller
        :items="filteredSearchMasterList"
        :minItemSize="25"
        :item-size="45"
        v-slot="{ item }"
        style="
          width: 420px;
          overflow-y: auto;
          overflow-x: hidden;
          max-height: 300px;
        "
      >
        <v-hover v-slot="{ hover }">
          <div :class="{ hovered: hover }" style="display: grid; align-items: center;" class="unselectable px-3" @click="itemClicked(item)">
            <div style="grid-area: 1/1/1/1; justify-self: start;">
              <UserPopup v-if="item.type === 'user'" :user_id="item.user_id" :tooltip="false" />
              <ContactPopup v-else-if="item.type === 'contact'" :contact_id="item.contact_id" :tooltip="false" />
            </div>
            <div style="grid-area: 1/1/1/1; justify-self: left; margin-left: 55px;" class="text-center">
              <div>
                {{ item.fullName }}
              </div>
              <div class="text-caption" style="color: #757575">
                {{ item.type }}
              </div>
            </div>
            <div style="grid-area: 1/1/1/1; justify-self: end;">

              <v-btn-toggle borderless rounded background-color="green lighten-3" style="align-items: center;">
                <!-- cell phone button / endpoint button -->
                 <v-tooltip :text="item.type === 'user' ? 'endpoint' : phoneFormat(item.ph0)" v-if="item.type == 'user'" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon @click="contactCall(item, item.ph0)">
                      <v-icon size="small" color="green darken 1">mdi-face-agent</v-icon>
                    </v-btn>
                  </template>
                  </v-tooltip>
                <v-tooltip :text="item.type === 'user' ? 'endpoint' : phoneFormat(item.ph1)" v-if="item.ph1" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon @click="contactCall(item, item.ph1)">
                      <v-icon size="small" color="green darken 1">mdi-cellphone</v-icon>
                    </v-btn>
                  </template>

                </v-tooltip>
                <!-- home phone button -->
                <v-tooltip :text="phoneFormat(item.ph2)" v-if="item.ph2" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon @click="contactCall(item, item.ph2)">
                      <v-icon size="small" color="green darken 1">mdi-home</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
                <!-- work phone button -->
                <v-tooltip :text="phoneFormat(item.ph3)" v-if="item.ph3" location="bottom">
                  <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon @click="contactCall(item, item.ph3)">
                      <v-icon size="small" color="green darken 1">mdi-briefcase</v-icon>
                    </v-btn>
                  </template>
                </v-tooltip>
              </v-btn-toggle>
            </div>
          </div>
        </v-hover>
      </RecycleScroller>
    </v-card>
  </v-menu>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import UserPopup from "@/components/Avatar/UserPopup";
import ContactPopup from "@/components/Avatar/ContactPopup";
import { phoneFormat } from "@/util/util";
import EventBus from "@/eventBus";

export default {
  name: "DefaultDisplay",
  components: { UserPopup, ContactPopup },
  mixins: [colorMixin],
  props: {
    searchMasterList: Array,
    callEnabled: Boolean,
  },
  watch: {},
  data() {
    return {
      textfieldInput: null,
    };
  },
  computed: {
    validNumber: function() {
      if (!this.textfieldInput) return false;
      let parsedNum = this.textfieldInput.replace(/\D/g, "");
      return parsedNum.length === 10 && /^\d+$/.test(parsedNum);
    },
    filteredSearchMasterList: function() {
      
      return this.searchMasterList.filter((entry) => {
        if (!this.textfieldInput) return false;
        if (entry.type == "user" && this.textfieldInput.toLowerCase() === "staff") return true;
        else if(this.textfieldInput.includes('(')) {
          const phoneString = this.textfieldInput.slice(this.textfieldInput.indexOf("(")).replace(/[ ()-]/g, '');
          return ( entry.ph1 && entry.ph1.startsWith(phoneString))
          || ( entry.ph2 && entry.ph2.startsWith(phoneString))
          || ( entry.ph3 && entry.ph3.startsWith(phoneString))
        }

        return entry.fullName.toLowerCase().includes(this.textfieldInput.toLowerCase())
        || (entry.ph1 && entry.ph1.includes(this.textfieldInput))
        || ( entry.ph2 && entry.ph2.includes(this.textfieldInput))
        || (entry.ph3 && entry.ph3.includes(this.textfieldInput))
      }).sort((val1, val2) => {
        const pattern = /^\d+/;
        if(pattern.test(this.textfieldInput) || this.textfieldInput.includes('(')) {
          const testString = pattern.test(this.textfieldInput) ? this.textfieldInput : this.textfieldInput.slice(this.textfieldInput.indexOf("(")).replace(/[ ()-]/g, '');
          const val1ph1 = val1.ph1 && val1.ph1.startsWith(testString);
          const val2ph1 = val2.ph1 && val2.ph1.startsWith(testString);
          if(val2ph1 && val1ph1) return 0;
          else if(val2ph1) return 1;
          else if(val1ph1) return -1;

          const val1ph2 = val1.ph2 && val1.ph2.startsWith(testString);
          const val2ph2 = val2.ph2 && val2.ph2.startsWith(testString);
          if(val2ph2 && val1ph2) return 0;
          else if(val2ph2) return 1;
          else if(val1ph2) return -1;

          const val1ph3 = val1.ph3 && val1.ph3.startsWith(testString);
          const val2ph3 = val2.ph3 && val2.ph3.startsWith(testString);
          if(val2ph3 && val1ph3) return 0;
          else if(val2ph3) return 1;
          else if(val1ph3) return -1;

        } else {
          const val1name = val1.fullName.toLowerCase().startsWith(this.textfieldInput.toLowerCase);
          const val2name = val2.fullName.toLowerCase().startsWith(this.textfieldInput.toLowerCase);
          if(val1name && val2name) return 0;
          else if(val2name) return 1;
          else if(val1name) return -1;
        }
        
        return 0;
      });
    },
  },
  methods: {
    contactCall(callee, calleeNumber) {
      console.log(this.callEnabled);
      if(!this.callEnabled) return;

      console.log('Contact call - Callee', callee)
      let calleeFirstName = null;
      let calleeLastName = null;

      if (callee.fullName) {
        // Will split the full name into an array, where the first element will be the first name
        // and the second element will be the last name (if there is one).
        const nameArray = callee.fullName.split(" ");
        calleeFirstName = nameArray[0];
        if (nameArray.length > 1) calleeLastName = nameArray[1];
      }

      // If the person we are calling is a user, then we will send the user_id instead of the contact_id.
      if (callee.type === "user") this.$emit("contactCallPressed", calleeFirstName, calleeLastName, calleeNumber);
      else this.$emit("contactCallPressed", calleeFirstName, calleeLastName, calleeNumber);
    },
    itemClicked(item) {
      //console.log(item);
      if(item.type == 'user') {
        console.log("user is clicked!!!!!!!!!!");
      }

      console.log("consolng the item");
      console.log(JSON.stringify(item));

      let id = item.user_id; 

      if(item.contact_id){
        id =  item.contact_id; 
      }
      let type = typeof id;
      if(this.$route.path != "/frontdesk")
        this.$router.push({ path: "/frontdesk" });
      // EventBus.$emit("changeFrontDesk", item.contact_id);
      console.log("consoling id", id, type);
      EventBus.$emit("changeFrontDesk", { contact_id: id, type: item.type });
    },
    phoneFormat,
  },
  mounted(){
    //if this default display is for gift card generate search, initilize textinput field with default clicked contact name
    if(this.GiftCardSearch){
      this.textfieldInput = `${this.contactState.first_name} ${this.contactState.last_name}`;
    }
    EventBus.$on("updateSearchML",(data)=>{
      if(data.type === 'add'){
          data.contact.id = this.searchMasterList.length;
          this.searchMasterList.push(data.contact);
      }
      if(data.type === 'delete'){
        const index =  contactBinarySearch(this.searchMasterList,data.contact);// data.contact hodlds contact_id
        if (index[0] !== -1) {
          this.searchMasterList.splice(index[0], 1);
        }
      }
      if(data.type === 'edit'){
        const index =  contactBinarySearch(this.searchMasterList,data.contact.contact_id);
        if (index[0] !== -1) {
          data.contact.id = index[0];//replacing original index
          this.searchMasterList.splice(index[0], 1,data.contact);
        }
      }
      
    });
  }
};
</script>
