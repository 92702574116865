import EventBus from "@/eventBus";

var socket = null;
 const contactobj = (response)=>{
  const contact = {
    id: 0,// it will update in default display
    type: "contact",
    fullName:
      (response.first_name || "") +
      (response.first_name && response.last_name
        ? ` ${response.last_name}`
        : ""),
    ph1: response.ph1,
    ph2: response.ph2,
    ph3: response.ph3,
    contact_id : response.contact_id,
  };
  return contact;
}


function socketOnOpen() {
  console.info("socket opened", socket);
}

function socketOnError(error) {
  console.error(error);
  EventBus.$emit("alert", "Error connecting to server");
}

function socketOnMessage(message, store) {
  let m = JSON.parse(message.data);
  // m.type is the message type
  // m.message is the message
  console.log("LOG M IN SOCKET ",m);
  console.log(m.type);
  switch (m.type) {
    // Here are the cases involving the Plivo module.
    // --------------------------------------------------------------------------------
    case "addOrUpdateConference":
      store.commit("plivo/addOrUpdateConference", m.message);
      break;
    case "removeConference":
      store.commit("plivo/removeConference", m.message);
      break;
    case "updateUserDictionary":
      store.dispatch("plivo/updateUserDictionary", m.message);
      break;
    // Here are cases involving the convos module
    // --------------------------------------------------------------------------------
    case "addMessage":
      store.commit("convos/addMessage", {
        convo_id: m.message.convo_id,
        message: m.message,
        user_id: store.getters.userId,
      });
      break;
    case "addConvo":
      // add the new convo to the convos state
      store.commit("convos/addNewConvo", { convo: m.message.convo, user_id: store.getters.userId });
      // add the new convo to its corresponding system process
      if (m.message.system_object.system_process === "outstanding claim")
        store.commit("osclaims/addConvo", {
          convo: m.message.convo,
          DSSClaimsID: m.message.system_object.system_process_id,
        });
      else if (m.message.system_object.system_process === "recall")
        store.commit("recall/addConvo", {
          convo: m.message.convo,
          DSSRecallID: m.message.system_object.system_process_id,
        });

      break;
    case "updateConvoStatus":
      store.commit("convos/updateConvoStatus", m.message);
      break;
    case "addParticipant":
      store.commit("convos/addParticipant", m.message);
      break;
    case "deleteParticipant":
      store.commit("convos/deleteParticipant", m.message);
      break;
    case "addReminder":
      m.message.forUser = m.message.reminder.reminder_for_who === store.getters.userId;
      store.commit("convos/addReminder", m.message);
      break;
    case "deleteReminder":
      m.message.forUser = m.message.reminder_for_who === store.getters.userId;
      store.commit("convos/deleteReminder", m.message);
      break;
    case "updateReminder":
      m.message.forUser = m.message.reminder_for_who === store.getters.userId;
      store.commit("convos/updateReminder", m.message);
      break;
    case "completeMention":
      m.message.forUser = m.message.user_id === store.getters.userId;
      store.commit("convos/completeMention", m.message);
      break;
    case "addMissedCall":
      if(m.message.length > 0) {
        //Adding the contact details to received messages to persist the left panel phone icons
        if(m.message[0].isContact) {
        let contacts = store.getters['people/contactsHash']
        let contact = contacts[parseInt(m.message[0].contact_id)]
          m.message[0]["ph1"] = contact.ph1
          m.message[0]["ph2"] = contact.ph2
          m.message[0]["ph3"] = contact.ph3
          m.message[0]["textable1"] = contact.textable1
          m.message[0]["textable2"] = contact.textable2
          m.message[0]["textable3"] = contact.textable3
	  const typeHash = store.getters["people/contactTypes"];
          for (let type of typeHash) {
          	if(type.type_id == m.message[0]["contact_type"]){
                	 let typeName=type.type_name;
                	 m.message[0]["typeName"]=type.type_name;
               		 break;
          	}
          }
        } else {
          m.message[0]["phone_num"] = m.message[0].contact_id
        }
        store.commit("frontdesk/addNewCall", m.message)
        store.commit("frontdesk/callDetails", m.message)
      }
    break;
    // Contact Related
    case  "contact":
      if(m.message.length > 0) {
        //Adding the contact details to received messages to persist the left panel phone icons
        //let contacts = store.getters['people/contacts']
        //let contact = contacts.find(o => o.contact_id === parseInt(m.message[0].contact_id))
        console.log("after saving contact:"+m.message);
        store.commit('people/add_update_contacts',m.message[0]);
        store.commit('frontdesk/updateCallDetails',m.message);
        
        EventBus.$emit("updateSearchML",{contact :contactobj(m.message[0]) , type : "add"});
        
      }
      break;
    case "setContactType" :
      store.commit('people/add_update_contacts',m.message);
      store.dispatch("frontdesk/update_contactTypesdata", m.message, { root: true });//all_contact_types_data
      break;
    case "updateContact":
      store.commit('people/add_update_contacts',m.message);
      EventBus.$emit("updateSearchML",{contact :contactobj(m.message) , type : "edit"});
      break;
    case "deleteContact":
      EventBus.$emit("updateSearchML",{contact :parseInt(m.message) , type : "delete"});
      store.commit('people/delete_contact',parseInt(m.message));
      break;
    case "createContactType":
      store.commit('people/add_ContacTypes',m.message);
      break;
    case "updateContactType":
      store.commit('people/update_ContactTypes',m.message);
      break;
    case "addInternalMessage":
      store.commit("convos/addInternalMessage", {
        convo_id: m.message.convo_id,
        message: m.message,
        user_id: store.getters.userId,
      });
      break;
    case "addInternalConvo":
      m.message.convo.contact_id = parseInt(m.message.convo.contact_id);
      store.commit("convos/addInternalConvo", { ...m.message.convo });
      break;
    case "completeInternalMention":
      m.message.forUser = m.message.user_id === store.getters.userId;
      store.commit("convos/completeInternalMention", m.message);
      break;
    case "addInternalReminder":
      m.message.forUser = m.message.reminder.reminder_for_who === store.getters.userId;
      store.commit("convos/addInternalReminder", m.message);
      break;
    case "updateInternalReminder":
      m.message.forUser = m.message.reminder.reminder_for_who === store.getters.userId;
      store.commit("convos/updateInternalReminder", m.message);
      break;
    case "deleteInternalReminder":
      store.commit("convos/deleteInternalReminder", m.message);
      break;
    case "deleteInternalConvo":
      m.message.internal_convo_id = parseInt(m.message.internal_convo_id);
      store.commit("convos/deleteInternalConvo", m.message);
    case "subscription_details":
      store.commit("user",m.message);
      break;
      // Here are cases involving the tags module
      // -------------------------------------------------------------
    case "update_tags":
      store.commit("tags/update_Tag",m.message);// add and update
      break;
    case "deleteTag":
      store.commit("tags/delete_Tag",parseInt(m.message));
      break;
      // all_tagsdata
    case "addTagsToContact":
      store.dispatch("frontdesk/add_contactTagsdata",m.message);
      break;
    case "removeTagForContact":
      store.dispatch("frontdesk/remove_contactTagsdata",m.message);
      break;
    default:
      console.error("Socket message type " + message.type + "not recognized", message);
  }
}

function openConnection(store) {
  // don't do anything if the connection is opening or already open
  if (socket && (socket.readyState === 0 || socket.readyState === 1)) return;

  // don't do anything if the user is logged out
  if (!store.state.validUser) return;

  if (socket) console.error("Websocket Disconnected");

  // open the websocket connection
  socket = new WebSocket(process.env.VUE_APP_WS_URL);

  socket.onopen = () => socketOnOpen();
  socket.onclose = () => openConnection(store);
  socket.onerror = (error) => socketOnError(error);
  socket.onmessage = (message) => socketOnMessage(message, store);
}

export default function createSocketPlugin() {
  return (store) => {
    // if the user is aleady logged in, open the connection
    if (store.state.validUser) openConnection(store);

    // subscribe to all actions
    store.subscribeAction({
      after: (action, state) => {
        if (action.type === "logIn" && state.user) {
          openConnection(store, state);
        } else if (action.type === "logOut") {
          if (socket) socket.close();
          socket = null;
        }
      },
    });
  };
}
