<template>
  <v-menu v-if="userData" close-delay="160" offset-y open-on-click :disabled="noMenu" :close-on-content-click="false">
    <template v-slot:activator="{ props }">
      <div v-bind="props">
        <v-tooltip :disabled="!tooltip" location="top" :text="userData.username">
          <template v-slot:activator="{ props: tooltip_props }">
            <v-avatar
              v-bind="{...props, ...tooltip_props}"
              class="m-0"         
              :size="size"
              :color="hashedColor(userData.username)"
              style="margin-left: 4px;"
            >
              <span style="color: white" :style="dense ? 'font-size: .7rem' : 'font-size: .9rem'">
                {{ userData.username.substring(0, 2).toUpperCase() }}
              </span>
            </v-avatar>
          </template>
        </v-tooltip>
      </div>
    </template>
    <v-card>
      <div class="justify-center mx-2 py-2">
        <div class="mx-auto text-center">
          <v-avatar size="2.2rem" v-bind:color="hashedColor(userData.username)" style="margin-left: 4px;">
            <span style="font-size: .9rem; color: white">
              {{ userData.username.substring(0, 2).toUpperCase() }}
            </span>
          </v-avatar>
          <h4>{{ userData.username }}</h4>
          <p class="caption mb-1">
            {{ userData.first_name + " " + userData.last_name + " - " + userData.role }}
          </p>
          <p v-if="userData.email" class="caption mb-1">
            <v-divider class="my-1"></v-divider>
            {{ userData.email }}
          </p>
          <v-divider class="my-1"></v-divider>
          <p class="caption mb-1">
            {{ "Send " + userData.first_name + " a Direct Message" }}
          </p>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import { mapActions } from "vuex";

export default {
  name: "UserPopup",
  mixins: [colorMixin],
  props: {
    // true if the avatar should have a visible badge. (The badge slot can be used to set the badge)
    badge: { type: Boolean, default: false },
    // true if the avatar should be small
    dense: { type: Boolean, default: false },
    // true if the avatar should have a tooltip
    tooltip: { type: Boolean, default: true },
    // the following props are passed directly to UserPopup (see UserPopup for more information)
    user_id: { type: Number, default: null },
    noMenu: { type: Boolean, default: false },
  },
  data() {
    return {
      userData: null,
    };
  },
  created() {
    if (this.user_id) this.getUserInfo();
    else console.error("user_id MUST be provided in UserPopup");
  },
  watch: {
    user_id: function() {
      this.getUserInfo();
    },
  },
  computed: {
    size: function() {
      return this.dense ? "1.5rem" : "2.3rem";
    },
  },
  methods: {
    ...mapActions("people", ["getUser"]),
    getUserInfo() {
      this.getUser(this.user_id)
        .then((user) => {
          this.userData = user;
        })
        .catch((e) => console.log(e));
    },
  },
};
</script>
