<template>
  <div>
    <div v-if="openFilter" style="flex: 10 10; position: relative">
        <AllFilter 
          class="shared-overlay" 
          v-model:type_selectedValues="type_selectedValues"
          :conTypes="contactTypes"
          v-model:tag_selectedValues="tag_selectedValues"
          :conTags="tags"
          v-model:od_selectedValue="od_selectedValue"
          :odList="odFilterList"
          @apply-filter="filter_handle"
          @closeFilter="openFilter=false"
          @get-odData="getContactfrom_OD"
          />

    </div>
   
  <v-card outlined style="height: 100%; display: flex; flex-direction: column">
    <v-card-subtitle v-if="isFilterApplied && od_selectedValue" style="opacity: 1; margin-bottom: 0%;">
      <v-icon color="primary">mdi-hospital-building</v-icon>{{ od_selectedValue }}</v-card-subtitle>
         <!-- Search Field  -->
  <div style="margin-bottom: 13%; margin-top: 0%;padding: 10px; justify-content: space-around;">     
    <v-text-field
        v-model="searchTerm"
        prepend-inner-icon="mdi-magnify"
        :append-icon="isFilterApplied ? 'mdi-filter-multiple':'mdi-filter-outline'"
        label="Search"
        single-line
        hide-details
        variant="underlined"
        style="width:auto ; max-height: 5px;"
        @click:append="openFilter=!openFilter"
      ></v-text-field>
  </div>
         
    <!-- Action Buttons -->
    
      <div style="display: flex;padding: 10px;justify-content: space-around;">
        <button @click="applyMassTag" v-if="isFilterApplied && can_manual_tagging && filterCalls.length" 
          style="margin: 5px;border: solid 1px black;height: fit-content;border-radius: 7px;"><v-icon
            color="primary">mdi-tag-plus</v-icon><v-tooltip activator="parent" location="bottom"
            >Apply Tag</v-tooltip></button>

        <button @click="changePanelToAll"
          style="margin: 5px;border: solid 1px black;height: fit-content;border-radius: 7px;"><v-icon
            color="primary">mdi-phone</v-icon></button>

        <button @click="changePanelToTOday"
          style="margin: 5px;border: solid 1px black;height: fit-content;border-radius: 7px;"><v-icon
            color="primary">mdi-bell</v-icon></button>

        <button @click="changePanelToLater"
          style="margin: 5px;border: solid 1px black;height: fit-content;border-radius: 7px;"><v-icon
            color="primary">mdi-bell-plus</v-icon></button>
        <!-- <AiButton @clicked="aiClicked"></AiButton> -->
        
      </div>
      <div v-if="enable_selection" style="display: flex;padding: 0px; margin-top: -10%; margin-bottom: -10%;">
        <v-checkbox label="Select All" @click="selectAllContacts"></v-checkbox>
      </div>
      
    <div v-if="!loading && !filterCalls.length" class="text-caption text-center" style="color: #757575">
      No calls or messages found
    </div>
    <template v-else-if="loading">
        <v-progress-linear indeterminate color="deep-purple-accent-4"></v-progress-linear>
    </template>
    <template >
      <RecycleScroller :items="filterCalls" :item-size="45" key-field="seqId" v-slot="{ item }"
        style="flex: 1 1 100px; overflow-y: auto; overflow-x: hidden"  @scroll="handleScroll"
        >
        <v-hover>
          <template v-slot:default="{ isHovering, props: hoverProps }">
            <div v-bind="hoverProps" @click="selectContact(item.contact_id, item)" :class="{
              highlighted: call.contact_id == item.contact_id || selected_contacts.includes(item.contact_id) && isFilterApplied,
              hovered: isHovering && call.contact_id !== item.contact_id && !enable_selection,
            }" style="
              display: flex;
              align-items: center;
              justify-content: space-between;
              height: 45px;
            " class="unselectable" :id="item.contact_id">

              <div style="flex: 1 1 1; display: flex; align-items: center;" class="ml-2">
                <div style="flex: 1 1 1;">
                  <ContactPopup v-if="contactByCall(item)" dense :contact_id="contactByCall(item)" :tooltip="true"
                    :showProfile="false" />
                  <div v-else>
                    <v-tooltip location="top" text="Add number to contact">
                      <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" icon variant="text" size="x-small">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template>
                    </v-tooltip>
                  </div>
                </div>
                <div style="flex: 1 1 1; display: flex; flex-direction: column;" class="ml-2">
                  <div style="flex: 1 1 1; line-height: 1.25rem; font-size: 15px; white-space: nowrap;">
                    {{ formattedName(item) }}
                  </div>
                  <div style="flex: 1 1 1; display: flex; line-height: 1rem; color: #757575;" class="text-caption">
                    <div style="flex: 1 1 1;" class="mr-1">
                      <v-tooltip location="right" :text="calcTooltip(item)">
                        <template v-slot:activator="{ props }">
                          <v-icon v-bind="props" size="small" v-if="item.call_status === 1 || item.call_missed === 1"
                            color="red">
                            mdi-phone-missed
                          </v-icon>
                          <v-icon v-bind="props" size="small"
                            v-else-if="item.call_status === 3 || item.call_missed === 3" color="primary">
                            mdi-phone-incoming
                          </v-icon>
                          <v-icon v-bind="props" size="small" v-else-if="item.comm_direction === 'incoming'"
                            color="red">
                            mdi-message-outline
                          </v-icon>
                          <v-icon v-bind="props" size="small"
                            v-else-if="item.comm_direction === 'outgoing' || item.comm_direction === 'sent' || item.comm_direction === 'queued' || item.comm_direction === 'delivered'"
                            color="primary">
                            mdi-message-outline
                          </v-icon>
                          <v-icon v-bind="props" size="small"
                            v-else-if="item.comm_direction == 'failed' || item.comm_direction === 'undelivered' || item.comm_direction === 'rejected'"
                            color="red">

                            mdi-message-alert
                          </v-icon>
                          <v-icon v-bind="props" size="small" v-else-if="item.comm_direction === 'outbound'"
                            color="primary">
                            mdi-phone-outgoing
                          </v-icon>
                          <!-- we have item.comm_direction === -1 && item.call_status === -1 for od data-->
                          <v-icon v-bind="props" size="small" v-else-if="item.comm_direction !== -1 && item.call_status !== -1">mdi-phone-incoming</v-icon>
                          <!-- <v-icon  v-bind="props" size="small" v-else>mdi-phone-incoming</v-icon> -->
                        </template>

                      </v-tooltip>
                    </div>
                    <div style="flex: 1 1 1">
                      {{ simpleDateFormat(item.date) }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="isHovering || call.contact_id == item.contact_id"
                style="flex: 1 1 1; display: flex; align-items: center;" class="mr-2">
                <div style="grid-area: 1/1/1/1; justify-self: end;">
                  <div style="display: inline-flex; vertical-align: middle;">
                    <!-- cell phone button / endpoint button -->
                    <v-tooltip v-if="item.ph1 && item.ph1" location="bottom" :text="phoneFormat(item.ph1)">
                      <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon
                          @click="phoneCall(item.first_name, item.ph1)">
                          <v-icon size="small" color="green">mdi-cellphone</v-icon>

                        </v-btn>
                      </template>
                    </v-tooltip>
                    <!-- home phone button -->

                    <v-tooltip v-if="item.ph2 && item.ph2" location="bottom" :text="phoneFormat(item.ph2)">
                      <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon
                          @click="phoneCall(item.first_name, item.ph2)">
                          <v-icon size="small" color="green">mdi-home</v-icon>

                        </v-btn>
                      </template>
                    </v-tooltip>
                    <!-- work phone button -->

                    <v-tooltip v-if="item.ph3 && item.ph3" location="bottom" :text="phoneFormat(item.ph3)">
                      <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon
                          @click="phoneCall(item.first_name, item.ph3)">
                          <v-icon size="small" color="green">mdi-briefcase</v-icon>

                        </v-btn>
                      </template>
                    </v-tooltip>

                    <v-tooltip v-if="item.phone_num && item.phone_num" location="bottom"
                      :text="phoneFormat(item.phone_num)">
                      <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon
                          @click="phoneCall(item.first_name, item.phone_num)">
                          <v-icon size="small" color="green">mdi-phone</v-icon>

                        </v-btn>
                      </template>
                    </v-tooltip>
                     <!-- tag button -->
                     <v-tooltip v-if="item.tag_id && item.tag_id.length > 0" location="bottom" :array="getTagNames(item.tag_id)">
                      <template v-slot:activator="{ props }">
                        <v-btn v-bind="props" variant="text" style="width: 25px;height: 25px;" icon>
                          <v-icon size="small" color="green">mdi-tag</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:default>
                        <div v-for="tagName in getTagNames(item.tag_id)" :key="tagName">
                          <p>{{ tagName }}</p>
                        </div>
                      </template>                      
                    </v-tooltip>                  
                  </div>
                </div>
              </div>
            </div>
          </template>
        </v-hover>
      </RecycleScroller>
    </template>
  </v-card>
</div>
</template>

<script>
import ContactPopup from "@/components/Avatar/ContactPopup";
import momentMixin from "@/mixins/momentMixin";
import { mapGetters, mapActions, mapState,mapMutations } from "vuex";
import MiscService from "@/services/miscService";
import EventBus from "@/eventBus";
import { phoneFormat} from "@/util/util";
import ConvoService from "@/services/convoService";
import { differenceInHours, parseISO } from 'date-fns';
import AllFilter from "./AllFilter.vue";
import AiButton from '../UIComponents/AiButton.vue';
export default {
  name: "LeftPanel",

  components: { ContactPopup,AllFilter,AiButton },

  mixins: [momentMixin],
  data() {
    return {
      searchTerm: "",
      checkboxpriority: 0,
      checkboxall: 1,
      allcontactTypes:[] ,
      type_selectedValues: [],
      combinedData: [],
      showCreateTagDialog: false,
      tag_selectedValues:[],
      all_tags:[],
      od_selectedValue:null,   
      selectedItem: null,
      openFilter:false,
      enable_selection : false,
      selected_contacts : [],
      select_AllContacts : false,
      isBottom :false
    };
  },
  computed: {
    ...mapGetters("frontdesk", [
      "calls",
      "call",
      "get_AllContact_Typesdata",
      "voicemails",
      "unreturnedCalls",
      "numberByCall",
      "contactByCall",
      "loading",
      "all_calls",
      "get_alltags_data",
    ]),
    ...mapState("frontdesk", ["allCalls","alltags_data"]),
    ...mapGetters("people", ["contactName", "contactsHash","contactTypes"]),
    ...mapActions("people",["getContactTypes"]),
    ...mapGetters("tags",["tags","tagsHash","can_manual_tagging"]),
    ...mapGetters("frontdesk",["odFilterList","odData","loading"]),
    
    // the calls to display in the current tab 
    filterCalls: function () {
      console.log("Consoling in filtercalls in frontend", this.calls);
      if (!this.checkboxpriority && this.checkboxall) {
        console.log("First is running");
        return this.textSearch(this.calls);
      } else if (this.checkboxpriority && !this.checkboxall) {
        console.log("###second is running");
        console.log("In unattented calls ", typeof this.calls, this.calls);
        let filteredcalls = [];
        for (let c in this.calls) {
          let item = this.calls[c];
          if (item.comm_direction && item.comm_direction === "incoming") {
            // true for sms messages
            filteredcalls.push(item);
          } else if (
            typeof item.call_missed != "undefined" &&
            item.call_missed === 1
          ) {
            filteredcalls.push(item);
          } else {
            if (
              typeof item.call_status != "undefined" &&
              item.call_status === 1
            ) {
              filteredcalls.push(item);
            }
          }
        }
        return this.textSearch(filteredcalls);
      }
      return [];
    },

    console: () => console,
    window: () => window,
    isFilterApplied(){
      return this.type_selectedValues.length || this.tag_selectedValues.length || this.od_selectedValue;
    },
    
  },

  watch:{
    call(){
        this.openFilter= false;
    },
    od_selectedValue(val){
      if(val===null){
        this.$store.commit('frontdesk/set_odData', []);
      }
         
    }
  },
  async created() {
      await this.getContactTypes;
    EventBus.$on("changeFrontDesk", async (data) => {
    
      //      // Access individual emitted properties
      const contact_id = data.contact_id;
      const itemType = data.type;
      let contact = this.contactsHash[contact_id];
      // let allCalls = await MiscService.getAllCalls(contact_id);
      let allCalls;
      var call = { call_missed: null, comm_direction: null, date: null };

      if (itemType == "user") {
        let user_comm = [];
        console.log("Inside the if stament in left panel");
        let lastTimestamp = 0;
        let { data } = await MiscService.getAllUserCalls(contact_id, lastTimestamp);
        console.log("This is the first timestamp", lastTimestamp + 604800000);
        lastTimestamp = data.lastTimestamp;
        console.log("Printing last  intimestamp ", lastTimestamp)
        console.log("Printing data", data.commData);
        user_comm.push(...data.commData);
        console.log("Printing data", user_comm);
        this.setAllCalls(user_comm);

        let flag = 0;
        const fetchDataAndProcess = async () => {
          let { data } = await MiscService.getAllUserCalls(contact_id, lastTimestamp);
          console.log("Inside the asycn funcction in left panel and flat = ", flag);
          lastTimestamp = data.lastTimestamp;
          console.log("Printing last timestamp ", lastTimestamp);
          if (data.commData) {
            user_comm.unshift(...data.commData);
            console.log("Consoling user_comm in loop", user_comm);
          }
          // console.log("Printing data", data.commData);
          flag = flag + 1;

        };


        // Call the function initially
        fetchDataAndProcess();
        // if (data.commData.length > 0) {
        //     call = allCalls.commData[allCalls.commData.length - 1];
        //   }

        // Set up an interval to run the function every 10 seconds
        const intervalId = setInterval(fetchDataAndProcess, 3000);
        setTimeout(() => clearInterval(intervalId), 10 * 3000);

      } else {
        
        allCalls = await MiscService.getAllCalls(contact_id);
        this.setAllCalls(allCalls.data);
        // console.log("Printing for contact ",allCalls.data);
        if (allCalls.length > 0) call = allCalls.data[allCalls.data.length - 1];
      }

      console.log("now printing all calls");
      // console.log(allCalls);

      // if(allCalls.length > 0)
      //   call = allCalls.data[allCalls.data.length - 1];

      if (contact) {
        // console.log("now printing the contact", call);
        this.changeCall({
          call_status: call.call_missed,
          comm_direction: call.comm_direction,
          contact_id: contact_id.toString(),
          date: call.date,
          first_name: contact.first_name,
          last_name: contact.last_name,
          ph1: contact.ph1,
          ph2: contact.ph2,
          ph3: contact.ph3,
          textable1: contact.textable1,
          textable2: contact.textable2,
          textable3: contact.textable3,
        });
      } else {
        // console.log("this is user call ", call);
        this.changeCall({
          call_status: call.call_missed,
          comm_direction: call.comm_direction,
          contact_id: contact_id,
          date: call.date,
          first_name: contact_id,
          last_name: contact_id,
          phone_num: contact_id,
        });
      }
      this.updateRightPanel(contact_id);
      //     let contact = this.contacts.find(o => o.contact_id === contact_id);
      //      // Access individual emitted properties
      // // const contact_id = data.contact_id;
      // // const itemType = data.type;
      //     console.log("now printing contact id in left panel vue", contact_id);
      //     console.log("Caling left panel vue now !!!!!!!");

      //     // console.log("the item type is" +itemType);

      //     // let allCalls;

      //     // if(itemType == 'user'){
      //     //    allCalls = await MiscService.getAllUserCalls(contact_id);
      //     // }
      //     // else {
      //      let allCalls = await MiscService.getAllCalls(contact_id);
      //     // }

      //     console.log("now printing allCalls");
      //     console.log(allCalls);
      //     var call = {call_missed: null, comm_direction: null, date: null};

      //     if(allCalls.length > 0)
      //       call = allCalls.data[allCalls.data.length - 1];

      //     this.setAllCalls(allCalls.data);
      //     if(contact) {
      //       this.changeCall({call_status: call.call_missed, comm_direction: call.comm_direction, contact_id: contact_id.toString(), date: call.date, first_name: contact.first_name,
      //         last_name: contact.last_name, ph1: contact.ph1, ph2: contact.ph2, ph3: contact.ph3, textable1: contact.textable1, textable2: contact.textable2, textable3: contact.textable3});
      //     } else {
      //       this.changeCall({call_status: call.call_missed, comm_direction: call.comm_direction, contact_id: contact_id, date: call.date, first_name: contact_id, last_name: contact_id,
      //         phone_num: contact_id});
      //     }
      //     this.updateRightPanel(contact_id);
    });
  },
  methods: {
    ...mapMutations("frontdesk", ["changeCall", "setAllCalls", "getAllCalls", "Calls", "all_Calls", "setcalls"]),
    ...mapMutations(["setContact"]),
    ...mapMutations("convos", ["setInternalConvos"]),
    ...mapMutations(['changeRightPanelStatus']),
    ...mapActions("tags",["applyMassTagforContact"]),
    ...mapActions("frontdesk",["getODdata"]),

    selectContact(contact_id, item){
        if(this.enable_selection){
            console.log("Selected Contact"+contact_id);
            if (this.selected_contacts.includes(contact_id)) {
                // Remove id if already selected
                this.selected_contacts = this.selected_contacts.filter((id) => id !== contact_id);
            }else {
                // Add id if not selected
                this.selected_contacts.push(contact_id);
            }
        }else{
          this.getAllCallsforLeftPanel(contact_id, item)
        }
    },
   selectAllContacts(){
    this.select_AllContacts = !this.select_AllContacts;
      if(this.selected_contacts.length === this.combinedData.length && !this.select_AllContacts ){
        this.selected_contacts = [];
      }else if(this.select_AllContacts){
        this.selected_contacts = this.combinedData.map((contact)=>contact.contact_id);
        console.log("Selected Contacts"+ this.selected_contacts);
      }
    },
    // lazy loading only for od filter
    handleScroll(event) {
      const scroller = event.target; 
      const { scrollTop, scrollHeight, clientHeight } = scroller;

      this.isBottom = scrollTop + clientHeight >= scrollHeight - 1; 
      // if we reached bottom of the scroller we are calling next offset of OD data
      if(this.isBottom){
        console.log("Reached Bottom");
        if(this.isFilterApplied && this.od_selectedValue && !this.loading){
          this.getContactfrom_OD();
        }
        
      }
    },
    getTagNames(tagIds) {
      let tagNames = [];
      for (let tagId of tagIds) {
        let tagName = this.tagsHash[tagId];
        if (tagName) {
          tagNames.push(tagName);
        }
      } 
      return tagNames;
    },    

    changePanelToAll() {
      this.Calls(this.all_calls)
      this.tag_selectedValues=[];
      this.type_selectedValues=[];
      this.od_selectedValue = null;
      this.searchTerm="";
      this.$store.commit('frontdesk/set_odData', []);
      this.openFilter = false;
    },
    async changePanelToTOday() {
      // this.panel = 1;
      this.Calls(this.all_calls)

      const convos = await ConvoService.getClientInternalConvosToday();

      const filteredCalls = this.all_calls.filter(call => {
        // Check if any convo in convos.data has a matching contact_id in convo_references
        return convos.data.some(convo => {
          return (convo.contact_id === call.contact_id);
        });
      });

      console.log("filteredCalls",filteredCalls);

      this.Calls(filteredCalls)
      this.changeRightPanelStatus(true);
      EventBus.$emit("populateRightPanel1");
    },
    async changePanelToLater() {
      // this.panel = 2;
      this.Calls(this.all_calls)
      const convos = await ConvoService.getClientInternalConvosLater();

      const filteredCalls = this.all_calls.filter(call => {
        // Check if any convo in convos.data has a matching contact_id in convo_references
       return convos.data.some(convo => {
          return (convo.contact_id === call.contact_id);
        });
      });
      this.Calls(filteredCalls)
      this.changeRightPanelStatus(true);
      EventBus.$emit("populateRightPanel1");
    },
    phoneFormat,
    //union filter
    filter_handle() {
      let combinedSet = new Set();

      // Filter type_selectedValues
      if (this.type_selectedValues.length !== 0) {
        let selectedTypeData = this.type_selectedValues
          .flatMap(item => this.get_AllContact_Typesdata[item] || []);
        selectedTypeData.forEach(id => combinedSet.add(id));
      }

      // Filter tag_selectedValues
      if (this.tag_selectedValues.length !== 0) {
        let selectedTagData = Object.keys(this.get_alltags_data)
          .filter(key => this.tag_selectedValues.includes(key))
          .flatMap(key => this.get_alltags_data[key] || []);
        selectedTagData.forEach(id => combinedSet.add(id));
      }

      // If no filters are selected it will show all calls
      if (this.type_selectedValues.length === 0 && this.tag_selectedValues.length === 0) {
        this.Calls(this.all_calls);
        return;
      }

      // Convert the Set to an array 
      this.combinedData = Array.from(combinedSet);
      this.Calls(this.combinedData);

      console.log("Combined Data:", this.combinedData);
    },
    // 
    async getContactfrom_OD(){
      console.log("Selected OD List "+this.od_selectedValue);
        if(this.od_selectedValue){
          this.Calls(this.odData);
          await this.getODdata(this.od_selectedValue);
          this.Calls(this.odData);
        }else{
          this.$store.commit('frontdesk/odSelected_value', null);
          this.Calls(this.all_calls);
        }
    },
   
    applyMassTag(){
        this.enable_selection = !this.enable_selection
        this.selected_contacts = [];
        this.select_AllContacts = false;
        this.openFilter = false;
        EventBus.$emit("mass-tag");
        this.$store.commit('frontdesk/changeCall', {});
        this.$store.commit('frontdesk/setAllCalls', []);
    },
    calcTooltip(item) {
      if (item.call_status === 1 || item.call_missed === 1) return "Call Missed";
      else if (item.call_status === 3 || item.call_missed === 3) return "On Going";
      else if (item.comm_direction === 'outbound') return "Call Made";
      else if (!item.comm_direction || item.comm_direction === 'incoming') return "Message Received";
      else if (!item.comm_direction || item.comm_direction === 'outgoing' || item.comm_direction === 'sent' || item.comm_direction === 'queued' || item.comm_direction === 'delivered') return "Message Sent";
      else if (!item.comm_direction || (item.comm_direction == 'failed' || item.comm_direction === 'undelivered' || item.comm_direction === 'rejected')) return "Message Sending Failed";
      else if (item.comm_direction===-1 || item.call_status===-1) return "";
      else return "Call Received";
    },
    formattedName: function (item) {

      const formatted = phoneFormat(item.first_name);
      return formatted === item.first_name ? this.contactName(this.contactByCall(item)) : formatted;
    },
    async updateRightPanel(contact_id) {
      let _contact_id = parseInt(contact_id);
      let contact = this.contactsHash[contact_id];
      this.setContact(contact)
      let convos = await ConvoService.getClientInternalConvos(_contact_id);
      convos = convos.data;
      const conversationHash = this.allCalls
      convos.forEach((convo) => {
        let remainingHrs;
        if (convo.userReminder) {
          const givenDate = parseISO(convo.userReminder.reminder_date);
          const now = new Date();
          remainingHrs = differenceInHours(givenDate, now);
          convo.remaining_hr = remainingHrs;
        }
        else {
          convo.remaining_hr = null;
        }
        let conversation_id = convo.convo_references[0].conversation_id;
        conversationHash.forEach((convoHash) => {
          if (convoHash.comm_participant_id == conversation_id) {
            let jsonObject = Object.assign({}, convo.convo_references[0].conversation, convoHash);
            convo.comm_participant = jsonObject;
            if (jsonObject.call_id) {
              convo.comm_participant.call = {}
              convo.comm_participant.call.call_missed = jsonObject.call_missed
              convo.comm_participant.call.call_uuid = jsonObject.call_uuid
              convo.comm_participant.call.comm_ref = jsonObject.comm_ref
              convo.comm_participant.call.date = convo.date_created
            }
            else {
              convo.comm_participant.text_message = {}
              convo.comm_participant.text_message.direction = jsonObject.comm_direction
              convo.comm_participant.text_message.message = jsonObject.message
              convo.comm_participant.text_message.date = convo.date_created
            }

          }
        })
      })
      convos.sort((a, b) => {
        if (a.remaining_hr === null) return 1;  // If a is null, push it to the end
        if (b.remaining_hr === null) return -1; // If b is null, push it to the end
        return a.remaining_hr - b.remaining_hr; // Otherwise, compare the values
      });
      console.log("convos", convos)

      this.setInternalConvos(convos),
        EventBus.$emit(
          "populateRightPanel",
          contact ? contact : { phone_num: contact_id },
          convos
        );
    },
    async getAllCallsforLeftPanel(contactId, call) {

      //console.log(call);
      let allCalls;
      if (call.contact_id == call.first_name && call.contact_id == call.last_name && call.contact_id == call.phone_num) {
        console.log('get all calls for phone')
        allCalls = await MiscService.getAllCallsForPhone(call.phone_num);
      } else {
        console.log('get all calls for contact')
        allCalls = await MiscService.getAllCallsForContact(contactId);
      }
      this.setAllCalls(allCalls.data)
      this.changeCall(call)

      this.updateRightPanel(contactId);
    },
    phoneCall(name, number) {
      EventBus.$emit("call-event", { name, number });
    },
    textSearch(inputCalls) {
      console.log("enter");
      if (!this.searchTerm || this.searchTerm === "") {
        return inputCalls;
      } else {
        console.log("In textSearch ", typeof searchTerm, searchTerm);
        var searchTerm = this.searchTerm.toLowerCase();
        console.log("In free text search", searchTerm);
        let filteredcalls = [];
        for (let c in inputCalls) {
          console.log("in contact match check ", c);
          let item = inputCalls[c];
          if (item.ph1 && item.ph1.includes(searchTerm)) {
            //console.log("c.ph1 ", typeof item.contact_id, item.contact_id)
            console.log("In phone search with ph1");
            filteredcalls.push(item);
            continue;
          }
          if (item.ph2 && item.ph2.includes(searchTerm)) {
            console.log("In phone search with ph2");
            filteredcalls.push(item);
            continue;
          }
          if (item.ph3 && item.ph3.includes(searchTerm)) {
            console.log("In phone search with ph3");
            filteredcalls.push(item);
            continue;
          }
          console.log("In phone search with full name");

          if (item.first_name && item.last_name) {
            let record_fullName =
              item.first_name.toLowerCase() +
              " " +
              item.last_name.toLowerCase();
            if (
              item.first_name.toLowerCase().includes(searchTerm) ||
              item.last_name.toLowerCase().includes(searchTerm) ||
              record_fullName.includes(searchTerm)
            )
              filteredcalls.push(item);
          }
        } // end of for
        return filteredcalls;
      } // end of else
    }, 
    aiClicked(){
      console.log("Ai Button Clicked");
      this.$store.commit('frontdesk/changeCall', {});
      this.$store.commit('frontdesk/setAllCalls', []);
    }
  },// end of method
  mounted(){
      EventBus.$on("mass-tags-list",async (data)=>{
        // apply tags for filtered contacts
        const payload = {contact_ids : this.selected_contacts, tag_ids : data.selectedTags, message : data.massTagMessage};
        try {
          await this.applyMassTagforContact(payload);
        
        } catch (error) {
          console.log(error);
        }finally{
          this.selected_contacts = [];
          this.enable_selection=false;
        }
      });
      EventBus.$on("tagModelClosed",()=>{
        this.selected_contacts = [];
        this.enable_selection=false;
      })
      EventBus.$on("CloseFilter",()=>{
       this.openFilter = false;
      })
  },
  beforeUnmount() {
      this.$store.commit('frontdesk/changeCall', {}),
      this.$store.commit('frontdesk/setAllCalls', []),
      this.$store.commit('frontdesk/set_odData', []),
      EventBus.$off("changeFrontDesk");
    },
};
</script>
<style scoped>
.shared-overlay {
 position: absolute;
 margin-top: 20%;
  left: 100%;
  transform: translate(-10%, -10%);
  z-index:2;
  width: 100%;
  height: 100%;
  
}
</style>