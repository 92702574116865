import Vue from "vue";
import Vuex from "vuex";
import router from "@/router";
import AuthService from "@/services/authService";
import EventBus from "@/eventBus";
import plivoBrowserSdk from "@/plugins/plivo";

// import vuex modules
import { moduleHome } from "./modules/moduleHome";
import { moduleOSClaims } from "./modules/moduleOSClaims";
import { moduleRecall } from "./modules/moduleRecall";
import { moduleFrontDesk } from "./modules/moduleFrontDesk";
import { moduleVPI } from "./modules/moduleVPI";
import { modulePlivo } from "./modules/modulePlivo";
import { modulePeople } from "./modules/modulePeople";
import { moduleConvos } from "./modules/moduleConvos";
import { moduleTags } from "./modules/moduleTags";

// import vues-extensions plugin (allows the state to be reset easily)
import { createStore } from "vuex-extensions";

// import vuex-persistedstate plugin
import createPersistedState from "vuex-persistedstate";
const dataState = createPersistedState({
  paths: ["user", "clientName", "clientId", "validUser"],
  storage: window.sessionStorage,
});

// import custom socket plugin
import socketPlugin from "./socketPlugin";

const store = createStore(Vuex.Store, {
  modules: {
    home: moduleHome,
    osclaims: moduleOSClaims,
    recall: moduleRecall,
    frontdesk: moduleFrontDesk,
    vpi: moduleVPI,
    plivo: modulePlivo,
    people: modulePeople,
    convos: moduleConvos,
    tags : moduleTags
  },
  plugins: [dataState, socketPlugin()],
  state: {
    // information about the user that is logged in (null if not logged in)
    user: null,
    // the name of the office
    clientName: "",
    clientId: null,
    validUser: '',
    isPanelVisible:false,
    contactState:{},
    internalSelectedConvo:{},
  },
  getters: {
    getContactState(state){
      return state.contactState
    },
    clientName(state) {
      return state.clientName;
    },
    clientId(state) {
      return state.clientId;
    },
    userId(state) {
      return state.user ? state.user.user_id : "";
    },
    username(state) {
      return state.user ? state.user.username : "";
    },
    firstName(state) {
      return state.user ? state.user.first_name : "";
    },
    lastName(state) {
      return state.user ? state.user.last_name : "";
    },
    fullName(state, getters) {
      return (getters.firstName || "") + (getters.firstName ? " " : "") + (getters.lastName || "");
    },
    userRole(state) {
      return state.user ? state.user.role : "";
    },
    userInitials(state, getters) {
      return getters.username.substring(0, 2).toUpperCase();
    },
    plivoUser(state) {
      return state.user ? state.user.plivo_user : "";
    },
    plivoPass(state) {
      return state.user ? state.user.plivo_pass : "";
    },
    email(state) {
      return state.user ? state.user.email : "";
    },
    darkMode(state) {
      return state.user ? state.user.dark_mode : false;
    },
    loggedIn(state) {
      return state.user != null;
    },
    getContact(state){
      return state.contactState;
    }
  },
  // Mutations Must Be Synchronous!
  mutations: {
    setContact(state,newContact){
      state.contactState=newContact
    },
    user(state, newUser) {
      state.user = newUser;
    },
    clientName(state, name) {
      state.clientName = name;
    },
    clientId(state, id) {
      state.clientId = id;
    },
    changeDarkMode(state, dark_mode) {
      state.user.dark_mode = dark_mode;
    },
    loggedIn(state, status) {
      state.validUser = status
    },
    changeRightPanelStatus(state,status){
      state.isPanelVisible=status;
    }
  },
  actions: {
    logIn({ commit, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        if (payload.username === "" || payload.password === "" || payload.client_id === "") {
          return reject("All the fields must be filled in!");
        }

        AuthService.login({ username: payload.username, password: payload.password,client_id: payload.client_id })
          .then((response) => {
            if (response.data === "" || response.data.user.plivo_user === undefined) {
              return reject("Something went wrong");
            }
            var user = {
              user_id: response.data.user.user_id,
              username: response.data.user.username,
              plivo_user: response.data.user.plivo_user,
              plivo_pass: response.data.user.plivo_pass,
              first_name: response.data.user.first_name,
              last_name: response.data.user.last_name,
              role: response.data.user.role,
              email: response.data.user.email,
              dark_mode: response.data.user.dark_mode,
            };
            commit("user", user);
            commit("loggedIn", true);
            commit("clientName", response.data.user.client.name);
            commit("clientId", payload.client_id);
            EventBus.$emit("toggleDarkMode", user.dark_mode);
            dispatch("people/getUsers", { root: true });
            dispatch("people/getContacts", { root: true });
            router.push({ path: "/frontdesk" });
            dispatch("logInPlivoSDK");
            return resolve("successfully logged in");
          })
          .catch((e) => {
            console.log(e);
            return reject("Username or password incorrect!");
          });
      });
    },
    // logs in the plivo browser sdk
    logInPlivoSDK({ getters }) {
      console.log(this)
      if (getters.loggedIn && !plivoBrowserSdk.client.isLoggedIn)
        plivoBrowserSdk.client.login(getters.plivoUser, getters.plivoPass);
    },
    logOut({ commit }) {
      plivoBrowserSdk.client.logout();
      EventBus.$emit("toggleDarkMode", false);
      commit("user", null);
      commit("clientName", "");
      commit("clientId", null);
      commit("loggedIn", false);
      router.push({ path: "/" });
      // reset the store to the default configuration
      this.replaceState({
        home: {},
        osclaims: {},
        recall: {},
        frontdesk: {},
        vpi: {},
        plivo: {},
        people: {},
        convos: {},
      });
      AuthService.logout().catch((e) => console.log(e));
      window.location.reload(true);
    },
  },
});

export default store;
