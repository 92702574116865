<template>
  <div class="pricing-container">
    <header class="header">
      <h1 class="main-heading">Dental Software Services (DSS)</h1>
      <p>Your partner in dental practice management</p>
      <p v-if="is_trial" class="trial-notice">Enjoy all three features for 28 days!</p>
    </header>

    <div v-for="(plans, renewalType) in groupedPlans" :key="renewalType" class="pricing-plans">
      <h2 class="section-heading">{{ renewalType.charAt(0).toUpperCase() + renewalType.slice(1) }} Plans</h2>

      <div class="plans-section">
        <div 
          v-for="(plan, index) in plans" 
          :key="`${renewalType}-${index}`" 
          class="plan-card" 
          @click="selectPlan(plan)" 
          @mouseover="hoveredPlan = `${renewalType}-${index}`" 
          @mouseleave="hoveredPlan = null"
          :class="{'hovered': hoveredPlan === `${renewalType}-${index}`}"
        >
          <h3 @click.stop="selectPlan(plan)">{{ plan.name }}</h3>
          <p class="price">
            ${{ plan.price }}
            <span v-if="renewalType === 'monthly'">/month</span>
            <span v-if="renewalType === 'yearly'">/year</span>
          </p>
          <ul class="features">
            <li v-for="feature in plan.features" :key="feature">
              <span class="checkmark">✔</span> {{ feature }}
            </li>
          </ul>
          <button v-if="is_trial" class="trial-button" @click.stop="selectPlan(plan)">Start Trial</button>
          <button v-else class="trial-button" @click.stop="selectPlan(plan)">BUY NOW</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminService from "@/services/adminService";
export default {
  data() {
    return {
      planDetails: [],
      hoveredPlan: null,
      is_trial: null,
      trial_days: null,
    };
  },
  computed: {
    groupedPlans() {
      return this.planDetails.reduce((groups, plan) => {
        if (!groups[plan.renewal]) {
          groups[plan.renewal] = [];
        }
        groups[plan.renewal].push(plan);
        return groups;
      }, {});
    },
  },
  methods: {
    async selectPlan(plan) {
      try {
        const response = await AdminService.subscribe(plan.id);
        window.location.href = response.data.checkoutUrl;
      } catch (error) {
        console.error("Error redirecting to checkout:", error);
        alert("Failed to start subscription. Please try again.");
      }
    },
    async fetchPlanDetails() {
      try {
        const details = await AdminService.planDetails();
        this.planDetails = details.data.plans;
        this.trial_days = details.data.trial_days;
      } catch (err) {
        console.error("Error fetching plan details:", err);
      }
    },
    async fetchSubscriptionDetails() {
      try {
        const response = await AdminService.getSubscriptionDetails();
        this.is_trial = response.data.is_trial;
      } catch (error) {
        console.error("Failed to fetch subscription details:", error);
      }
    },
  },
  async mounted() {
    await this.fetchPlanDetails();
    await this.fetchSubscriptionDetails();
  }
};
</script>

<style scoped>
.pricing-container {
  font-family: Arial, sans-serif;
  text-align: center;
  margin: 20px;
  color: #333;
}

.header {
  margin-bottom: 30px;
}

.main-heading {
  font-size: 3em;
  color: #007acc;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-shadow: 2px 2px #d0d0d0;
  margin-bottom: 10px;
}

.header p {
  font-size: 1.2em;
  color: #555;
}

.trial-notice {
  font-size: 1em;
  color: #28a745;
  font-weight: bold;
  margin-top: 10px;
}

.section-heading {
  font-size: 2em;
  color: #0056a3;
  border-bottom: 2px solid #0056a3;
  display: inline-block;
  margin-bottom: 20px;
  padding-bottom: 5px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.plans-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.plan-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 250px;
  padding: 20px;
  background: #f9f9f9;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, background-color 0.3s;
  cursor: pointer;
}

.plan-card:hover {
  transform: scale(1.05);
}

.plan-card.hovered {
  background-color: #e6f7ff;
}

.plan-card h3 {
  color: #007acc;
  font-size: 1.5em;
  margin-bottom: 10px;
  cursor: pointer;
}

.plan-card .price {
  font-size: 2em;
  color: #333;
  margin: 15px 0;
}

.plan-card .price span {
  font-size: 0.8em;
  color: #555;
}

.plan-card .features {
  text-align: left;
  margin: 15px 0;
  padding: 0;
  list-style: none;
}

.plan-card .features li {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.plan-card .features .checkmark {
  color: #28a745;
  font-weight: bold;
  margin-right: 8px;
}

.trial-button {
  background-color: #007acc;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.trial-button:hover {
  background-color: #0056a3;
}
</style>