export function phoneFormat(number) {
    return phoneFormatTitle(number);
    //return "(" + number.substring(0,3) + ") " + number.substring(3, 6) + "-" + number.substring(6);
}

export function phoneFormatTitle(number) {
    const pattern = /^\d{10}$/;
    if(pattern.test(number))
        return "(" + number.substring(0,3) + ") " + number.substring(3, 6) + "-" + number.substring(6);
    else return number;
}

export const states = ['Alabama','Alaska','American Samoa','Arizona','Arkansas','California','Colorado','Connecticut','Delaware','District of Columbia','Federated States of Micronesia','Florida','Georgia','Guam','Hawaii','Idaho','Illinois','Indiana','Iowa','Kansas','Kentucky','Louisiana','Maine','Marshall Islands','Maryland','Massachusetts','Michigan','Minnesota','Mississippi','Missouri','Montana','Nebraska','Nevada','New Hampshire','New Jersey','New Mexico','New York','North Carolina','North Dakota','Northern Mariana Islands','Ohio','Oklahoma','Oregon','Palau','Pennsylvania','Puerto Rico','Rhode Island','South Carolina','South Dakota','Tennessee','Texas','Utah','Vermont','Virgin Island','Virginia','Washington','West Virginia','Wisconsin','Wyoming'];

export function designationToList(bits) {
    let str = "";
    for(let i = 0; i < 10; i++) {
        if(bits % 2 == 1) {
            str += (i + ",");
        }
        bits = bits >> 1;
    }

    return str.substring(0, str.length - 1)
}

export function designationToArray(bits) {
    let arr = [];
    for(let i = 0; i < 10; i++) {
        if(bits % 2 == 1) {
            arr.push(i);
        }
        bits = bits >> 1;
    }

    return arr;
}

export function displayTime(timeStamp) {

    const date = new Date(Number(timeStamp)); 
    let hours = date.getHours();
    const isPM = hours >= 12;
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12; 

    const minutes = date.getMinutes();
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${isPM ? 'PM' : 'AM'}`;
    return formattedTime;
}
export function displayDateTime(timeStamp) {

    const date = new Date(Number(timeStamp)); 
    let hours = date.getHours();
    const isPM = hours >= 12;
    if (hours > 12) hours -= 12;
    if (hours === 0) hours = 12; 

    const minutes = date.getMinutes();
    const formattedTime = `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${isPM ? 'PM' : 'AM'}`;

   
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' }); 
    const year = date.getFullYear();

    const formattedDate = `${day} ${month} ${year}`;

    return `${formattedDate}, ${formattedTime}`;
}
// contactBinarySearch is for searching contact details.
// It is used in searchMasterList and contacts array.
// It is replacement for find & indexOf js functions.
// It will return both value and particular array index.( [index,value] )
export function contactBinarySearch(contact_array,target_id){

    const firstContactIndex = contact_array.findIndex(item => 'contact_id' in item); // omitted users from search masterList
    console.log("BS firstContactIndex",firstContactIndex);
    
    if (firstContactIndex === -1) return  [-1,null];
    let low =firstContactIndex;
    let high = contact_array.length -1;
    let index = -1;
    let value = null;
    while(low <= high){
      let mid = Math.floor((low + high) / 2);
      if (contact_array[mid].contact_id === target_id) {
            index = mid;
            value = contact_array[mid];
            return [index,value];
       } 
      if (contact_array[mid].contact_id < target_id) {
            low = mid + 1; 
      } else {
        high = mid - 1; 
      }
    }
    return [index,value];
}