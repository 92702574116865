<template>
  <div style="height: 100%; display: flex; flex-direction: column;" class="list">
    <!-- <v-btn style="margin: 2%;" @click="getAllProcess">
      Get All Conversation
    </v-btn>
    <v-btn style="margin: 2%;" @click="getTodayProcessConvos">
      Get Today's Conversation
    </v-btn> -->
    <div style="display:flex">
      <v-select style="margin: 15px" label="Select a Process" :items="allProcess" v-model="process"  variant="outlined"></v-select>
      <v-dialog max-width="500">
        <template v-if="userRole.toLowerCase() == 'admin'" v-slot:activator="{ props: activatorProps }">
          <div>
            <v-btn width="10px" v-bind="activatorProps" style="border: 1px solid black;"
              variant="flat"><v-icon>mdi-plus</v-icon></v-btn>
          </div>
        </template>

      
        <template v-slot:default="{ isActive }">
          <v-card title="Create a new process">
            <v-card-text>
              <v-text-field v-model="newProcess" label="newProcess"></v-text-field>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="() => { createProcess(); isActive.value = false; }" text="Create"></v-btn>
              <v-btn text="Close" @click="isActive.value = false"></v-btn>

            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>
    <div style="display: flex; justify-content: space-around; margin-top: -1rem;">
      <v-badge :model-value="nowClaims.some((c) => c.convo.unread)" dot overlap>
        <v-tooltip location="right" text="Now">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" style="flex: 1 1 1;" @click="selectedTab = 'now'"
              v-bind:color="selectedTab === 'now' ? 'primary' : ''">
              mdi-calendar-alert
            </v-icon>
          </template>
        </v-tooltip>
      </v-badge>
      <v-badge :model-value="laterClaims.some((c) => c.convo.unread)" dot overlap>
        <v-tooltip location="right" text="Later">
          <template v-slot:activator="{ props }">
            <v-icon v-bind="props" style="flex: 1 1 1;" @click="selectedTab = 'later'"
              v-bind:color="selectedTab === 'later' ? 'primary' : ''">
              mdi-calendar-blank-outline
            </v-icon>
          </template>
        </v-tooltip>
      </v-badge>
    </div>
    <v-divider style="margin: 0.5rem 0 0.5rem 0;" />
    <div v-if="!loading && !displayedClaims.length" class="text-caption text-center" style="color: #757575">
      No Conversations Here
    </div>
    <RecycleScroller v-else :items="displayedClaims" :item-size="35" key-field="DSSClaimsID" v-slot="{ item, index }"
      style="flex: 1 1 0; overflow-y: auto;">
      <v-hover v-slot="{ hover }">
        <div @click="changeClaim(item)" :class="{
          highlighted: claim.DSSClaimsID === item.DSSClaimsID,
          hovered: hover && claim.DSSClaimsID !== item.DSSClaimsID,
        }" :style="index === participatingIndex && selectedTab !== 'now' ? 'border-top: 1px solid #777777;' : ''"
          style="display: flex; justify-content: space-between; align-items: center; height: 35px;"
          class="unselectable">
          <div style="flex: 1 1 1; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            class="mx-2 text-body-2" :class="item.convo.unread ? 'font-weight-bold' : ''">
            <v-tooltip v-if="item.convo.userMentioned > 0 && !item.convo.userReminder" location="top"
              text="You have an uncompleted task in this conversation with no reminder">
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" color="primary">
                  mdi-rhombus-medium
                </v-icon>
              </template>
            </v-tooltip>
            {{ item.convo.convo_name }}
          </div>
          <div v-if="item.convo.userReminder" style="flex: 1 1 1; min-width: max-content;" class="mr-1">
            <v-tooltip location="right" :text="moment(item.convo.userReminder.reminder_date).format('h:mmA M/D/YYYY')">
              <template v-slot:activator="{ props }">
                <div v-bind="props" style="font-size: .7rem;">
                  {{ fromNow(item.convo.userReminder.reminder_date) }}
                  <v-icon>mdi-alarm</v-icon>
                </div>
              </template>
            </v-tooltip>
          </div>
          <div v-else-if="selectedTab !== 'now' && !item.userParticipant">
            <v-tooltip location="right" text="You are not a participant of this conversation">
              <template v-slot:activator="{ props }">
                <div v-bind="props">
                  <v-icon>mdi-account-off</v-icon>
                </div>
              </template>
            </v-tooltip>
          </div>
        </div>
      </v-hover>
    </RecycleScroller>
    <v-dialog v-if="!isAiAgent" max-width="500">
      <template  v-slot:activator="{ props: activatorProps }">
        <div class="text-end">
          <v-btn width="10px" v-bind="activatorProps" style="border: 1px solid black;"
            variant="flat"><v-icon>mdi-plus</v-icon></v-btn>
        </div>
      </template>
      <template v-slot:default="{ isActive }">
        <v-card title="Create a new conversation">
          <v-card-text>
            <v-text-field v-model="title" label="Title"></v-text-field>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="() => { createConvo(); isActive.value = false; }" text="Create"></v-btn>
            <v-btn text="Close" @click="isActive.value = false"></v-btn>

          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import colorMixin from "@/mixins/colorMixin";
import momentMixin from "@/mixins/momentMixin";
import { mapGetters } from "vuex";
import { mapMutations, mapActions } from "vuex";
import ConvoService from "@/services/convoService";
import EventBus from "@/eventBus";
import MiscService from "@/services/miscService";

export default {
  name: "LeftPanel",
  mixins: [colorMixin, momentMixin],
  data() {
    return {
      // the current tab selected
      selectedTab: "now",
      title: '',
      process: '',
      allProcess: ["All Mentions"],
      newProcess: '',
    };
  },
  watch: {
    process(newValue, oldValue) {
      this.changeClaims([])
      this.changeClaim({})
      this.changeProcess(newValue);
      console.log("Process Clicked "+newValue);
      EventBus.$emit('process', this.process);
      if(newValue=="All Mentions"){
        this.getAllProcess()
        this.process="All Mentions"
      }
      else if(newValue=="Today's News"){
        this.getTodayProcessConvos()
        this.process="Today's News"
      }
      else if(newValue.length>0){
        this.retrieveClaims(newValue);
      }
    }
  },
  async created() {
    this.changeClaims([])
    this.changeClaim({})
    const allprocess = await MiscService.getProcess()
    allprocess.data.forEach(process => {
      this.allProcess.push(process.type_name);
    });
    console.log("ALL Process"+this.allProcess);
    
  },
  mounted() {
    this.getAllProcess();
    this.process="All Mentions";
    console.log("userRole:", this.userRole);
  },
  computed: {
    ...mapGetters("osclaims", ["claimsWithOpenConvos", "claim", "loading", "_process","isAiAgent"]),
    ...mapGetters(["userId", "userRole"]),
    nowClaims: function () {
      return this.claimsWithOpenConvos.filter((c) => {
        // all conversations with reminders after today
        if (c.convo.userReminder && this.moment(c.convo.userReminder.reminder_date).isAfter(this.moment(), "day"))
          return false;
        // all conversations where the user is not a participant
        if (!c.convo.convo_participants.some((p) => p.user_id === this.userId)) return false;
        // otherwise
        return true;
      });
    },
    laterClaims: function () {
      return this.claimsWithOpenConvos
        .filter((c) => {
          // all conversations with reminders after today
          if (c.convo.userReminder && this.moment(c.convo.userReminder.reminder_date).isAfter(this.moment(), "day"))
            return true;
          // all conversations where the user is not a participant
          if (!c.convo.convo_participants.some((p) => p.user_id === this.userId)) return true;
          // otherwise
          return false;
        })
        .map((c) => {
          c.userParticipant = c.convo.convo_participants.some((p) => p.user_id === this.userId);
          return c;
        });
    },
    // the claims to display in the current tab
    displayedClaims: function () {
      return this.selectedTab === "now" ? this.nowClaims : this.laterClaims;
    },
    participatingIndex: function () {
      return this.laterClaims.findIndex((c) => !c.userParticipant);
    },
  },
  methods: {
    ...mapMutations("osclaims", ["changeClaim", "changeClaims", "changeProcess"]),
    ...mapActions("osclaims", ["retrieveClaims"]),
    ...mapMutations("osclaims", ["changeLoading"]),
    getTodayProcessConvos(){
      this.retrieveClaims("todayNews");
      this.process="";
    },
    getAllProcess(){
      this.retrieveClaims("All Mentions");
      this.process="";
    },
    tooltip_date(item) {
      console.log(item.convo.userReminder)
      console.log(this.fromNow(item.convo.userReminder.reminder_date))
      console.log(this.moment(item.convo.userReminder.reminder_date).format('h:mmA M/D/YYYY'))
      return this.moment(item.convo.userReminder.reminder_date).format('h:mmA M/D/YYYY');
    },
    async createConvo() {
      console.log(this.process)
      if (this.title) {
        if (this.process.length == 0) {
          alert("select a process!")
          return
        }
        var c = {
          message: this.title,
          system_process: this.process,
          secured: 0,
          convo_name: this.title,
        };
        await ConvoService.addConvo(c).catch((e) => {
          alert("error in adding new convo")
          console.log(e);
        });
        this.retrieveClaims(this.process);
        this.title = '';
      }
      else {
        alert("add a title..")
      }
    },
    async createProcess() {
      if (this.newProcess) {
        try {
          // Restricted to create process name related to ai.
          if(this.newProcess.toLowerCase().includes('ai','ai agents','ai agent')){
            EventBus.$emit("alert", {
            message: "This name is Restricted",
            type: "warning-snack",
            });
            this.newProcess = ''
            return;
          }
          let new_process = await MiscService.addProcess(this.newProcess);
          let allprocess = await MiscService.getProcess()
          this.allProcess = ["All Mentions","Today's News"];
          allprocess.data.forEach(process => {
            this.allProcess.push(process.type_name);
          });
          this.newProcess = ''
        } catch (e) {
          console.log("error", e)
        }
      }
      else {
        alert("add a process..")
      }
    },
  },
};
</script>
