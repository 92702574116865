<template>
  <div class="success-page">
    <div class="subscription-details">
      <h3>Your Current Subscription</h3>
      <p v-if="subscription_details.is_trial"><strong>Plan:</strong> Trial</p>
      <p v-else><strong>Plan:</strong> {{getPlanName(subscription_details.plan)}}</p>
      <p v-if="subscription_details.is_trial"><strong>Tier:</strong> Premium </p>
      <p v-else><strong>Tier:</strong> {{ getTierName(subscription_details.plan) }}</p>
      <p><strong>Amount Paid:</strong> {{ subscription_details.amount_paid }} USD</p>
      <p><strong>Start Date:</strong> {{ subscription_details.start_date }}</p>
      <p><strong>Next Renewal Date:</strong> {{ subscription_details.expiry_date }}</p>
      <p><strong>Payment Mode:</strong> {{ subscription_details.payment_mode }}</p>
      <ul v-if="subscription_details.is_trial" class="features">
        <li v-for="feature in getFeatures('3')" :key="feature">
          <span class="checkmark">✔</span> {{ feature }}
        </li>
      </ul>
      <ul v-else class="features">
        <li v-for="feature in getFeatures(subscription_details.plan)" :key="feature">
          <span class="checkmark">✔</span> {{ feature }}
        </li>
      </ul>
    </div>

    <div class="switch-plan">
      <h2>Switch Plan</h2>
    </div>

    <div class="plan-selection">
      <div v-for="(plans, renewalType) in groupedPlans" :key="renewalType">
        <h3 class="plan-heading">{{ renewalType.charAt(0).toUpperCase() + renewalType.slice(1) }} Plans</h3>
        <div class="plan-card-row">
          <div 
            v-for="(plan, index) in plans" 
            :key="`plan-${renewalType}-${index}`" 
            class="plan-card"
            :class="{'current-plan': plan.id == subscription_details.plan}" 
            @click="showSwitchPlanPopup = plan.id"
            @mouseover="hoveredPlan = plan.id"
            @mouseleave="hoveredPlan = null"
          >
            <h3>{{ plan.name }}</h3>
            <p class="price">
              ${{ plan.price }} <span>/{{ plan.renewal }}</span>
            </p>
            <ul class="features">
              <li v-for="feature in plan.features" :key="feature">
                <span class="checkmark">✔</span> {{ feature }}
              </li>
            </ul>
          </div>
        </div>

        <div class="divider" v-if="renewalType !== Object.keys(groupedPlans).slice(-1)[0]"></div>
      </div>
    </div>

    <div v-if="showSwitchPlanPopup!=null" class="popup-overlay">
      <div class="popup-content">
        <h3>Confirm Switching plan</h3>
        <p>Are you sure you want to switch plan?</p>
        <button class="confirm-button" @click="changeInPlan(showSwitchPlanPopup)">Yes</button>
        <button class="cancel-button" @click="showSwitchPlanPopup = null">No</button>
      </div>
    </div>

    <div class="cancel-option">
      <button @click="showCancelPopup = true">Cancel subscription</button>
    </div>

    <div v-if="showCancelPopup" class="popup-overlay">
      <div class="popup-content">
        <h3>Confirm Cancellation</h3>
        <p>Are you sure you want to cancel your subscription?</p>
        <button class="confirm-button" @click="confirmCancellation">Yes, Cancel</button>
        <button class="cancel-button" @click="showCancelPopup = false">No, Keep It</button>
      </div>
    </div>
  </div>
</template>


<script>
import AdminService from "@/services/adminService";
export default {
  name: "ViewPlan",
  data() {
    return {
      subscription_details: {
        plan: null,
        start_date: null,
        expiry_date: null,
        payment_mode: null,
        amount_paid: null,
        upcoming_plan: [],
      },
      plans: [],
      hoveredPlan: null, 
      showCancelPopup: false,
      showSwitchPlanPopup: null,
    };
  },
  computed:{
    groupedPlans() {
      return this.plans.reduce((groups, plan) => {
        if (!groups[plan.renewal]) {
          groups[plan.renewal] = [];
        }
        groups[plan.renewal].push(plan);
        return groups;
      }, {});
    },
  },

  methods: {
    async fetchSubscriptionDetails() {
      try {
        const response = await AdminService.getSubscriptionDetails();
        this.subscription_details = response.data;
      } catch (error) {
        console.error("Failed to fetch subscription details:", error);
      }
    },
    async changeInPlan(plan) {
      try {
        const responce = await AdminService.switchPlan(plan);
        if(responce){
          const selectedPlan = this.plans.find((p) => p.id === plan);
          const { price, renewal } = selectedPlan;
          alert(`You will be charged ${price} dollars ${renewal} after your current plan ends`);
          this.showSwitchPlanPopup=null;
        }
      } catch (error) {
        console.error("Error changing plan:", error);
        alert("Failed to change subscription. Please try again.");
      }
    },
    async confirmCancellation() {
      try {
        const response = await AdminService.cancelSubscription();
        window.location.href = response.data.url;
      } catch (error) {
        console.error("Error canceling subscription:", error);
        alert("Failed to cancel subscription. Please try again.");
      } finally {
        this.showCancelPopup = false;
      }
    },
    getPlanName(planId) {
      for(const plan of this.plans){
        if(plan.id==planId){
          return plan.renewal.charAt(0).toUpperCase() + plan.renewal.slice(1) + " Plan";
        }
      }
      return "Unknown Plan";
    },
    getTierName(planId) {
      for(const plan of this.plans){
        if(plan.id==planId){
          return plan.name;
        }
      }
      return "Unknown Tier";
    },
    getFeatures(planId) {
      for(const plan of this.plans){
        if(plan.id==planId){
          return plan.features;
        }
      }
      return [];
    },
    async fetchPlanDetails() {
      try {
        const details = await AdminService.planDetails();
        this.plans = details.data.plans;
      } catch (err) {
        console.error("Error fetching plan details:", err);
      }
    }
  },
  async mounted() {
    await this.fetchSubscriptionDetails();
    await this.fetchPlanDetails();
  },
};
</script>

<style scoped>
.success-page {
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 20px;
  background-color: #f9f9f9;
  color: #333;
}

.plan-card .price span {
  font-size: 0.8em;
  color: #555;
}

.plan-card .features .checkmark {
  color: #28a745;
  font-weight: bold;
  margin-right: 8px;
}

.plan-card .select-button:hover {
  background-color: #45a049;
}

.current-plan-label {
  display: block;
  margin-top: 10px;
  color: #4CAF50;
  font-weight: bold;
}

.subscription-details {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  width: 80%;
  max-width: 500px;
  text-align: left;
}

.subscription-details h3 {
  font-size: 1.5em;
  color: #4CAF50;
}

.subscription-details p {
  margin: 10px 0;
  line-height: 1.5;
}

.subscription-details .features {
  list-style: none;
  padding: 0;
  margin: 15px 0;
  color: #555;
}

.subscription-details .features li {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.subscription-details .features .checkmark {
  color: #28a745;
  font-weight: bold;
  margin-right: 8px;
}

.cancel-option button {
  padding: 10px 20px;
  font-size: 1em;
  color: #fff;
  background-color: #f44336;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.cancel-option button:hover {
  background-color: #d32f2f;
}

.divider {
  height: 2px;
  background: linear-gradient(to right, #007acc, #28a745);
  margin: 20px 0;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.popup-content h3 {
  margin-bottom: 10px;
  font-size: 1.5em;
  color: #333;
}

.popup-content p {
  margin-bottom: 20px;
  color: #555;
}

.confirm-button,
.cancel-button {
  padding: 10px 20px;
  font-size: 1em;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin: 0 10px;
}

.confirm-button {
  background-color: #f44336;
  color: #fff;
}

.confirm-button:hover {
  background-color: #d32f2f;
}

.cancel-button {
  background-color: #4CAF50;
  color: #fff;
}

.cancel-button:hover {
  background-color: #45a049;
}

.plan-card-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.plan-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 250px;
  padding: 20px;
  background: #fff;
  text-align: left;
  transition: box-shadow 0.3s, background-color 0.3s;
}

.plan-card.current-plan {
  border: 2px solid #4CAF50;
  background-color: #e6f7ff;
}

.plan-card h3 {
  color: #007acc;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.plan-card .price {
  font-size: 1em;
  color: #333;
  margin: 15px 0;
}

.plan-card .features {
  list-style: none;
  padding: 0;
  margin: 15px 0;
  color: #555;
}

.plan-card .features li {
  margin: 5px 0;
  color: #28a745;
  font-size: 0.9em;
}

.plan-card .select-button
.current-plan-label {
  margin-top: 10px;
  display: block;
  font-size: 0.9em;
  text-align: center;
}

.select-button {
  padding: 8px 15px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.select-button:hover {
  background-color: #45a049;
}

.current-plan-label {
  color: #4CAF50;
  font-weight: bold;
}

.plan-selection {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  max-width: 800px;
}

.plan-card-row {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.plan-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 250px;
  padding: 20px;
  background: #fff;
  text-align: left;
  transition: transform 0.3s, background-color 0.3s;
  cursor: pointer;
}

.plan-card:hover {
  background-color: #f0f9ff;
  transform: scale(1.05);
  border-color: #4CAF50;
}

.plan-card.current-plan {
  border: 2px solid #4CAF50;
  background-color: #e6f7ff;
  pointer-events: none;
}

.plan-card h3 {
  color: #007acc;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.plan-card .price {
  font-size: 1em;
  color: #333;
  margin: 15px 0;
}

.plan-card .features {
  list-style: none;
  padding: 0;
  margin: 15px 0;
  color: #555;
}

.plan-card .features li {
  margin: 5px 0;
  color: #28a745;
  font-size: 0.9em;
}

.switch-plan h2 {
  text-align: center;
  margin: 20px 0;
  font-size: 1.5em;
  color: #4CAF50;
}

.plan-heading {
  font-size: 1.5em;
  color: #007acc;
  margin: 20px 0 10px;
  text-align: center;
}

.plan-card.locked {
  pointer-events: none;
  opacity: 0.6;
  border: 2px dashed #999;
}

</style>
