<template>
  <div style="display: flex; flex-direction: column; height: 100%">
    <ReminderPanel :toggleDialog="toggleReminder" />

    <div style="flex: 1 1">
      <div style="flex: 1" class="mt-3">
        <div v-if="contact" style="display: grid" class="mx-2">
          <div style="grid-area: 1/1/1/1; justify-self: start">
            <div class="tag-container">
              <ContactPopup
                v-if="call.contact_id && (contact.ph1 || contact.ph2 || contact.ph3)"
                dense
                :contact_id="parseInt(call.contact_id)"
                :tooltip="false"
              />
              <UnknownPopup
                v-if="call.contact_id && contact.phone_num"
                :phoneNum="contact.phone_num"
                :dense="true"
              />
             
              <!-- List of Tags -->
              <!-- <div v-if="call.contact_id && can_manual_tagging" class="add-tag-icon" @click="toggleTagPopup">
                <v-icon color="red" size="30">mdi-tag-plus</v-icon>
              </div> -->
              <div 
               :class="(massTagApply)?'display-top':'shared-overlay'"
              v-if="showTagPopup">
                <TagPopup 
                   v-if="showTagPopup"
                  :tags="filteredTags" 
                  :isMassTag="massTagApply"
                  @applyTag="toggleSelectionTag" 
                  @closeMenu="showTagPopup=false"
                  >
                </TagPopup>
                <div v-if="massTagApply">
                  <v-textarea v-model="massTagMessage"
                  variant="solo"
                  placeholder="Message (optional)" 
                  rows="1" auto-grow 
                  style="margin-top: 3%; width: max-content;">
                  <template v-slot:append-inner>
                  <div @click="applyMassTagEmit">
                    <v-tooltip activator="parent" location="bottom">Apply Mass Tag</v-tooltip>
                    <v-icon size="28" color="success" style="cursor: pointer; text-align: start;">mdi-tag-check</v-icon>
                  </div>
                </template>
                <template v-slot:append>
                  <v-btn elevation="5" @click="closeMassTagPopup" icon size="30">
                    <v-tooltip activator="parent" location="bottom">Close</v-tooltip>
                    <v-icon size="28" color="red" style="cursor: pointer; text-align: end;">mdi-close-circle</v-icon>
                  </v-btn>
                </template>
                </v-textarea>
                </div>
              </div>
             <!-- Displaying List of tags for contact's -->
                <template v-for="tagId in selectedTags" :key="tagId">
                  <template v-if="tagsHashnew[tagId]">
                          <v-tooltip :location="'bottom'" :text="tagsHashnew[tagId].tag_name">
                            <template v-slot:activator="{ props }">
                              <div v-bind="props">
                                <v-btn
                                size="25" color="white"  elevation="3" icon
                                >
                                <v-icon
                                  size="20"
                                  :color="tagsHashnew[tagId].tag_color"
                                  @click="toggleSelectionTag(tagId)"
                                >
                                <!-- remove tag on click -->
                                  mdi-{{ tagsHashnew[tagId].tag_icon }}
                                </v-icon>
                                </v-btn>
                              </div>
                            </template>
                          </v-tooltip>
                  </template>
                </template>
            </div>
            </div>
          <div style="grid-area: 1/1/1/1">
            <div class="displayCenter">
              <v-menu
                open-on-hover
                :close-on-content-click="false"
                close-delay="500"
                max-width="40"
                min-width="15"
                max-height="25"
                offset-y
                top
                right
                offset-x
                style="overflow-y: hidden"
              >
                <template v-slot:activator="{ props }">
                  <div
                    v-bind="props"
                    :class="
                      highlightedItem === 'contact' ? 'outlined rounded' : ''
                    "
                  >
                    {{ phoneFormatTitle(contact.full_name) }}
                  </div>
                </template>
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        size="15"
                        @click="createInternalConvo"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>Create new convo on this contact</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ props }">
                      <v-icon
                        v-bind="props"
                        style="margin-left: 5px"
                        size="15"
                        @click="showCommConvos"
                      >
                        mdi-chat
                      </v-icon>
                    </template>
                    <span>View convos on this contact</span>
                  </v-tooltip>
                </div>
              </v-menu>
            </div>
          </div>
          <div style="grid-area: 1/1/1/1; justify-self: end">
            <v-btn-toggle borderless>
              <!-- cell phone button / endpoint button -->
              <v-tooltip
                v-if="contact.ph1 && contact.ph1"
                location="bottom"
                :text="phoneFormat(contact.ph1)"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    class="ml-2"
                    color="green darken 1"
                    @click="phoneCall(contact.full_name, contact.ph1)"
                    >mdi-cellphone</v-icon
                  >
                </template>
              </v-tooltip>
              <!-- home phone button -->
              <v-tooltip
                v-if="contact.ph2 && contact.ph2"
                location="bottom"
                :text="phoneFormat(contact.ph2)"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    class="ml-2"
                    color="green darken 1"
                    @click="phoneCall(contact.full_name, contact.ph2)"
                    >mdi-home</v-icon
                  >
                </template>
              </v-tooltip>
              <!-- work phone button -->
              <v-tooltip
                v-if="contact.ph3 && contact.ph3"
                location="bottom"
                :text="phoneFormat(contact.ph3)"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    class="ml-2"
                    color="green darken 1"
                    @click="phoneCall(contact.full_name, contact.ph3)"
                    >mdi-briefcase</v-icon
                  >
                </template>
              </v-tooltip>
              <v-tooltip
                v-if="contact.phone_num && contact.phone_num"
                location="bottom"
                :text="phoneFormat(contact.phone_num)"
              >
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    class="ml-2"
                    color="green darken 1"
                    @click="phoneCall(contact.full_name, contact.phone_num)"
                    >mdi-phone</v-icon
                  >
                </template>
              </v-tooltip>
            </v-btn-toggle>
          </div>
        </div>
        <v-divider class="mt-1"></v-divider>
      </div>
    </div>
    <!-- This row holds the messenger header -->
    <!-- This row holds the message chat virtual scroller -->
    <div v-show="!showTagSettings" style="flex: 10 10; position: relative">
      <div
        v-show="allCalls"
        style="position: absolute; top: 0; left: 0; bottom: 0; right: 0"
      >
        <DynamicScroller
          :items="allCalls"
          key-field="comm_participant_id"
          style="height: 100%"
          :buffer="20000"
          ref="scroller"
         :min-item-size="54"
        
        >
          <template v-slot="{ item, index, active }">
            <DynamicScrollerItem :item="item" :active="active" :size-dependencies="[
            item.message,
          ]"
          :data-index="index"
          :data-active="active">
              <FDMessage
                :message="item"
                :prev="index === 0 ? null : allCalls[index - 1]"
                :index="index"
                :active="active"
                :contact="contact"
              />
            </DynamicScrollerItem>
          </template>
        </DynamicScroller>
      </div>
    </div>
    <!-- this row holds the message box -->
    <div v-show="!showTagSettings" style="flex: 1 1; position: relative">
      <v-overlay
        absolute
        :value="call && !call.contact_id"
        :opacity="0.08"
      ></v-overlay>
      <FDMessengerBox
        v-if="call.contact_id"
        :call="call"
        :contact="contact"
        :mostRecentNumber="mostRecentCall"
        :lastReceivedMsg="lastReceivedMsg"
        @reminderClicked="reminderClicked()"
      />
    </div>
   <!-- It will show Tag Settings -->
   <div v-if="showTagSettings && call.contact_id" style="flex: 10 10; position: relative; overflow-y:auto;">
      <div style="position: absolute; top: 0; left: 0; bottom: 0; right: 0">
       <TagSettings @closeTagSettings="showTagSettings=false"></TagSettings>
      </div>
    </div>
</div>
</template>

<script>
import FDMessage from "@/components/FrontDesk/FDMessage";
import FDMessengerBox from "@/components/FrontDesk/FDMessengerBox";
import ReminderPanel from "@/components/FrontDesk/ReminderPanel";
import ConvoService from "@/services/convoService";
import ContactPopup from "@/components/Avatar/ContactPopup";
import UnknownPopup from "@/components/Avatar/UnknownPopup";
import { mapGetters,mapMutations,mapState,mapActions } from "vuex";
import EventBus from "@/eventBus";
import { phoneFormat, phoneFormatTitle } from "@/util/util";
import TypeTagForm from "../AdminSettings/TypeTagForm.vue";
import TagSettings from "./TagSettings.vue";
import TagPopup from "../TagPopup.vue";
export default {
  name: "Messenger",
  components: {
    FDMessage,
    FDMessengerBox,
    ContactPopup,
    UnknownPopup,
    ReminderPanel,  
    TypeTagForm,
    TagSettings,
    TagPopup
  },
  props: {
    allCalls: { type: Array },
    contact: { type: Object, default: null },
  },
  emits: [],
  data() {
    return {
      loading: true,
      toggleReminder: false,
      toggleConvo: false,
      convoReference: null,
      mostRecentCall: "",
      highlightedItem: null,
      highlightedComm: null,
      lastReceivedMsg: null,
      selectedTags: [], 
      showTagPopup : false,
      massTagApply : false,
      massTagMessage : "",
      showTagSettings :false,
    };
  },
  computed: {
    ...mapGetters("frontdesk", ["getAllCalls", "call"]),
    ...mapGetters(['clientId']),
    ...mapState(['contactState']),   
    ...mapGetters("tags", [ "tagsHashnew","tags","tagsHash","can_manual_tagging"]),
    attachedTagIds() {
      return this.call.tag_id || [];
    },
    filteredTags() {
      const unselected = this.tags.filter(
        (tag) => !this.selectedTags.includes(tag.tag_id) && !tag.unlist && !tag.enable_auto// unlist tag only visible to already tagged contacts, if not it won't display and auto tag's not allowed for manual tagging
      );
      return unselected;
    },
   
  },
  watch: {
    "allCalls.length": function () {
      this.scrollToBottom();
      this.getMostRecentCall();
      EventBus.$emit("new_comm");
    },
    "contactState.contact_id" :  function(newID){
      if(newID){
        this.showTagSettings = false;
        this.showTagPopup = false;
        } 
        // update selected tags for new contact  
        if(this.call.tag_id){
          for(let i=0; i<this.call.tag_id.length;i++){
            this.selectedTags[i] = this.call.tag_id[i];
          }
        }else{
          this.selectedTags = [];
        }
        console.log("Selected Tags"+this.selectedTags);
        
    },
    contactState(){
      this.selectedTags = [];// while changing contact state we initiate this as empty, afterwards it will populate in above async function
      this.showTagSettings = false;
    }
  },

  created() {
    EventBus.$on("highlightConvoComm", (convo) => {
      this.highlightedItem = "comm";
      this.highlightedComm = convo;
      const scroller = this.$refs.scroller;
      if (scroller) scroller.scrollToItem(convo);
    });

    EventBus.$on("highlightContact", () => {
      this.highlightedItem = "contact";
    });

    EventBus.$on("clearHighlight", () => {
      this.highlightedItem = null;
      this.highlightedComm = null;
    });

    EventBus.$on("submitConvo", async (content, comm) => {
      const data = {
        message: content,
        contact_id: this.call.contact_id,
        conversation_id: comm,
      };

      await ConvoService.createInternalConvo(data);
    });
    this.getCalls();
    if (this.allCalls && this.allCalls.length > 0) this.getMostRecentCall();
  },

  beforeUnmount() {
    EventBus.$off("highlightConvoComm");
    EventBus.$off("highlightContact");
    EventBus.$off("clearHighlight");
    EventBus.$off("submitConvo");
  },

  methods: {
    phoneFormat,
    ...mapMutations(['changeRightPanelStatus']),
    ...mapActions("tags",["addTagforContact","removeTagForContact"]),
    phoneFormatTitle,
    getMostRecentCall() {
      let recentInbound = null;
      let recentOutbound = null;
      this.lastReceivedMsg = null;
      for (let i = this.allCalls.length - 1; i >= 0; i--) {
        if (
          (this.allCalls[i].comm_direction == "incoming" ||
            this.allCalls[i].comm_direction == "inbound") &&
          this.isTextable(this.allCalls[i])
        ) {
          if (recentInbound == null) {
            recentInbound = this.allCalls[i];
          }
          if (this.allCalls[i].comm_direction == "incoming") {
            this.lastReceivedMsg = this.allCalls[i];
          }
        } else if (
          this.isTextable(this.allCalls[i]) &&
          recentOutbound == null
        ) {
          recentOutbound = this.allCalls[i];
        }
      }
      this.mostRecentCall =
        recentInbound != null
          ? recentInbound.phone_num
          : recentOutbound != null
          ? recentOutbound.phone_num
          : null;
    },
    isTextable(message) {
      return (
        (message.phone_num == this.call.ph1 && this.call.textable1 == 1) ||
        (message.phone_num == this.call.ph2 && this.call.textable2 == 1) ||
        (message.phone_num == this.call.ph3 && this.call.textable3 == 1)
      );
    },
    // get the calls for this participants
    getCalls() {
      this.loading = false;
      this.scrollToBottom();
    },
    phoneCall(name, number) {
      EventBus.$emit("call-event", { name, number });
    },
    scrollToBottom() {
      console.log("start of scrollToBottom");
      this.$nextTick(() => {
        if (!this.$_scrollingToBottom) {
          this.scrollToPosition(999999999);
          this.$_scrollingToBottom = true;
        }
      });
      console.log("end of scrollToBottom");
    },
    reminderClicked() {
      console.log("start of reminderClicked");
      this.toggleReminder = !this.toggleReminder;
      //console.log(this.toggleReminder);
    },
    createInternalConvo() {
      EventBus.$emit("createConvo", null);
      this.changeRightPanelStatus(true);
    },
    showCommConvos() {
      this.changeRightPanelStatus(true);
      EventBus.$emit("showContactConvos");
    },
    checkHighlight(item) {
      return (
        this.highlightedItem === "comm" &&
        this.highlightedComm.convo_references[0].comm_participant_id ===
          item.comm_participant_id
      );
    },
    scrollToPosition(position) {
      console.log("start of scrollToPosition");
      if (!this.$refs.scroller) return;
      const scroller = this.$refs.scroller.$el;
      scroller.scrollTop = position;
      requestAnimationFrame(() => {
        scroller.scrollTop = position;
        setTimeout(() => {
          scroller.scrollTop = position;
          this.$_scrollingToBottom = false;
        }, 100);
      });

      console.log("end of scrollToPosition");
    },
  
  toggleTagPopup(){
      this.showTagPopup=!this.showTagPopup
    },
    closeMassTagPopup(){ 
      this.showTagPopup=false;
      this.massTagApply = false;
      this.selectedTags = [];
      EventBus.$emit("tagModelClosed");
    },
    
    applyMassTagEmit(){
     // it only passing selected tags
     const data = {selectedTags : this.selectedTags, massTagMessage : this.massTagMessage}
      EventBus.$emit("mass-tags-list",data)// apply tag for group of contacts based on filter
      this.showTagPopup=false;
      this.massTagApply = false;
      this.massTagMessage = "";
      this.selectedTags = [];
    },
    toggleSelectionTag(item) {
      if (this.selectedTags.includes(item)) {
        // Remove item if already selected
        this.selectedTags = this.selectedTags.filter((i) => i !== item);
        if(!this.massTagApply){
          this.remove_TagForContact(this.contactState.contact_id,item);
        }
      } else {
        // Add item if not selected
        this.selectedTags.push(item);
        if(!this.massTagApply){
          this.addTagToContact(this.contactState.contact_id,item);
          this.showTagPopup = false;
        }
      }
    },
   async addTagToContact(contact_id,tag_id){
    try {//sending single tag id as array format.
      let tagArray = [];
      tagArray.push(tag_id);
      let data={contact_id : contact_id,tag_ids : tagArray}
      await this.addTagforContact(data);
      } catch (error) {
        console.error(error);
      }
    },
    async remove_TagForContact(contact_id,tag_id){
        try {
          let data={contact_id : contact_id, tag_id : tag_id};
          await this.removeTagForContact(data);
        } catch (error) {
          console.error(error);
        }
    },
    
  },
  async mounted(){
    EventBus.$on("mass-tag",(data)=>{
        this.massTagApply = true;
        this.toggleTagPopup(); 
        this.selectedTags = [];
    });
    EventBus.$on("open-tag-settings",()=>{
      this.showTagSettings=true;
      this.showTagPopup = false;
    })
    
    if(this.contactState.contact_id){
         // update selected tags for new contact  
         for(let tagId in this.call.tag_id){
          if(!this.selectedTags.includes(tagId))
            this.selectedTags.push(tagId);
        }
        console.log("Selected Tags"+this.selectedTags);
    }
    
  },
  beforeUnmount() {
    EventBus.$off("highlightConvoComm");
    EventBus.$off("highlightContact");
    EventBus.$off("clearHighlight");
    EventBus.$off("submitConvo");
  },

};
</script>

<style scoped>
.outlined {
  background-color: var(--v-highlighted-base);
  border: 1px solid gray;
}

.rounded {
  border-radius: 2px;
}

.ml-2 {
  margin-left: 2px;
}

.tag-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.add-tag-icon {
  margin-right: 16px;
  cursor: pointer;
}

.shared-overlay {
 position: absolute;
 z-index:1;
 top:20%;
 /* left:20%; */
}
.display-top {
 position: absolute;
 z-index:1;
 top:30%;
}
</style>